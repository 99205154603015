import React, { Fragment } from "react";
import moment from "moment";
import get from "lodash.get";

import VendorColumn from "../../../Specs/VendorColumn";
import SpecNumberColumn from "./SpecNumberColumn";
import { Shipments } from "../../../PurchaseOrderDetail/ShippingContactSection/Shipments/Table";
import { DashIcon } from "../../../../ui/gridTableConfigs/detailPageProjects";
import DateColumn from "../../../../ui/BWGrid/columns/Date";
import { inputs } from "../../../../inputs/inputConfigs";
import { ShipmentFutureDeliveryWarning, WarningIcon } from "./components";
import { ApprovedToPay } from "./ApprovedToPay";
import { PRIMARY_DATE_FORMAT } from "../../../../../constants/formats";
import { isPaidShipment } from "../ManageShipments/gridProps";

export const columns = [
  "customNumber",
  "description",
  "area.name",
  "unitOfMeasure.name",
  "baseQuantity",
  "atticStock",
  "shipmentsCount",
  "estimatedShipDate",
  "estimatedDeliveryDate",
  "actualShipDate",
  "actualDeliveryDate",
  "approvedToPay",
];

const getApprovedToPay = (props, handleApproveToPay) => (
  <ApprovedToPay {...props} handleApproveToPay={handleApproveToPay} />
);

const getShipmentScore = ({
  estimatedShipDate,
  estimatedDeliveryDate,
  actualShipDate,
  actualDeliveryDate,
}) => {
  let score = 0;
  if (!estimatedShipDate) {
    score += 10;
  }
  if (!estimatedDeliveryDate) {
    score += 10;
  }
  if (!actualShipDate) {
    score += 10;
  }
  if (!actualDeliveryDate) {
    score += 10;
  }
  return score;
};

const buildSortingScoreObj = shipments => {
  const scoreObject = shipments.reduce(
    (
      previous,
      {
        id,
        estimatedShipDate,
        estimatedDeliveryDate,
        actualShipDate,
        actualDeliveryDate,
      }
    ) => {
      previous[id] = getShipmentScore({
        estimatedShipDate,
        estimatedDeliveryDate,
        actualShipDate,
        actualDeliveryDate,
      });
      return previous;
    },
    {}
  );
  return scoreObject;
};

const sortShipments = shipments => {
  const sortingScoreObj = buildSortingScoreObj(shipments);

  return shipments.sort((a, b) => {
    if (sortingScoreObj[a.id] !== sortingScoreObj[b.id]) {
      return sortingScoreObj[b.id] - sortingScoreObj[a.id];
    }

    if (a.actualDeliveryDate && b.actualDeliveryDate) {
      return new Date(a.actualDeliveryDate) - new Date(b.actualDeliveryDate);
    }

    if (a.actualShipDate && b.actualShipDate) {
      return new Date(a.actualShipDate) - new Date(b.actualShipDate);
    }

    return new Date(a.estimatedShipDate) - new Date(b.estimatedShipDate);
  });
};

export const getOutdatedShipment = shipments => {
  if (!shipments || shipments.length === 0) return;
  return sortShipments(shipments)[0];
};

export const ShipmentDate = (shipment, fieldName) => {
  if (!shipment) return <DashIcon />;
  return <DateColumn date={shipment[fieldName]} format={PRIMARY_DATE_FORMAT} />;
};

const dateAppendValues = targetColumn => (value, row) =>
  moment(value).isAfter(row.shipment[targetColumn], "day")
    ? { [targetColumn]: null }
    : {};

const columnOptions = {
  customNumber: {
    title: "Spec Number",
    render: SpecNumberColumn,
    filter: true,
  },
  description: { width: "300px", filter: true },
  "area.name": { title: "Area", filter: true },
  "purchaseOrder.vendor.name": {
    title: "Vendor",
    fill: true,
    render: VendorColumn,
    filter: true,
  },
  "unitOfMeasure.name": { title: "UOM" },
  baseQuantity: {
    sortingEnabled: false,
    title: "Base",
  },
  atticStock: {
    sortingEnabled: false,
    title: "Attic",
  },
  shipmentsCount: { title: "Shipments", align: "left", render: Shipments },
  estimatedShipDate: {
    title: "Estimated Ship Date",
    render: ({ shipment }) => ShipmentDate(shipment, "estimatedShipDate"),
    sortingEnabled: false,
    editable: true,
    editableOptions: {
      formatId: row => row.shipment?.id,
      formatValue: row => row.shipment?.estimatedShipDate,
      appendValues: dateAppendValues("estimatedDeliveryDate"),
    },
  },
  estimatedDeliveryDate: {
    title: "Estimated Delivery Date",
    render: ({ shipment }) => ShipmentDate(shipment, "estimatedDeliveryDate"),
    sortingEnabled: false,
    editable: true,
    editableOptions: {
      formatId: row => row.shipment?.id,
      formatValue: row => row.shipment?.estimatedDeliveryDate,
    },
    OuterComponent: function getOuterComponent(props) {
      return (
        <WarningIcon
          {...props}
          fieldName={"estimatedDeliveryDate"}
          targetField={"estimatedShipDate"}
        />
      );
    },
  },
  actualShipDate: {
    title: "Actual Ship Date",
    render: ({ shipment }) => ShipmentDate(shipment, "actualShipDate"),
    sortingEnabled: false,
    editable: true,
    editableOptions: {
      formatId: row => row.shipment?.id,
      formatValue: row => row.shipment?.actualShipDate,
      appendValues: dateAppendValues("actualDeliveryDate"),
    },
  },
  actualDeliveryDate: {
    title: "Actual Delivery Date",
    render: ({ shipment }) => ShipmentDate(shipment, "actualDeliveryDate"),
    sortingEnabled: false,
    editable: true,
    editableOptions: {
      formatId: row => row.shipment?.id,
      formatValue: row => row.shipment?.actualDeliveryDate,
      appendValues: (value, row) => {
        return get(row, "shipment.approvedToPay") &&
          isPaidShipment(
            row,
            get(row, "totalQuantityApprovedToPay"),
            get(row, "shipment.quantity")
          )
          ? {}
          : { [inputs.approvedToPay.name]: !!value };
      },
    },
    OuterComponent: function getOuterComponent(props) {
      return (
        <Fragment>
          <WarningIcon
            {...props}
            fieldName={"actualDeliveryDate"}
            targetField={"actualShipDate"}
          />
          <ShipmentFutureDeliveryWarning
            {...props}
            fieldName={"actualDeliveryDate"}
          />
        </Fragment>
      );
    },
  },
  approvedToPay: {},
};

export const getColumnOptions = handleApproveToPay => ({
  ...columnOptions,
  approvedToPay: {
    title: "Approved to Pay",
    render: props => getApprovedToPay(props, handleApproveToPay),
    sortingEnabled: true,
    editableOptions: {
      formatId: row => row.shipment?.id,
      formatValue: row => row.shipment?.approvedToPay,
    },
  },
});

export const isEditableRow = column => {
  return !!columnOptions[column.name].editable;
};
