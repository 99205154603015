import moment from "moment";

import { uploadFile } from "./filesActions";
import {
  performCreateRequest,
  initDataComponent,
  performUpdateRequest,
  performFindRequest,
  performRetrieveListRequest,
  performDeleteRequest,
} from "./dataComponentActions";
import PurchaseOrder from "../models/PurchaseOrder";
import PurchaseOrderFile from "../models/PurchaseOrderFile";
import { FILE_DATE_FORMAT } from "../constants/formats";

export const dataComponentId = "PODetail";
export const approvalFileDataComponentID = "UpdatePO";
export const poFilesDataComponentId = "POFilesDC";

const purchaseOrderDetailModifiers = [
  "withOnlyNumber",
  "withRevised",
  "withNeedsFollowUp",
  "withCancellable",
  "withName",
  "withShipmentsData",
];

export const uploadApprovalFile = (purchaseOrder, key, file) => {
  return async dispatch => {
    const ext = file.name.split(".").pop();
    const filename = `${Date.now()}.${ext}`;

    await dispatch(
      uploadFile(
        {
          key,
          filename,
        },
        file
      )
    );
    dispatch(
      performCreateRequest(approvalFileDataComponentID, {
        purchaseOrderId: purchaseOrder.id,
        file: {
          s3Key: `${key}/${filename}`,
          filename: file.name,
        },
      })
    );
  };
};

export function revertFromRevision(poId, revisionId, additionalParams = {}) {
  return async dispatch => {
    await dispatch(
      performUpdateRequest(
        "PODetail",
        poId,
        {},
        {
          revert_from_revision: revisionId,
          update_po_revision: true,
          ...additionalParams,
        }
      )
    );
    await dispatch(
      performFindRequest(
        dataComponentId,
        poId,
        {},
        {
          modifiers: purchaseOrderDetailModifiers,
        }
      )
    );
  };
}

export function fetchPurchaseOrder(purchaseOrderId, projectId) {
  return async dispatch => {
    dispatch(
      initDataComponent(
        dataComponentId,
        PurchaseOrder,
        [
          "approvalFiles.[file, user, revision]",
          "vendor.[location, discounts.program]",
          "project.[remitAddresses, freightVendor, designer.location, projectMemberContacts.contact, program]",
          "commodities",
          "purchaseOrderDiscounts.discount",
          "shipAddress.[location]",
          "shipVendorAddress.[vendor.location, location]",
          "currentRevision(withSnapshotCurrency).revisionActivities",
          "revisions(withSnapshotCurrency)",
          "projectCurrency.currency",
          "designer.location",
          "shipVendor.location",
        ],
        "purchase-orders",
        true,
        "v2"
      )
    );
    dispatch(
      performFindRequest(
        dataComponentId,
        purchaseOrderId,
        {
          $where: { projectId },
        },
        {
          modifiers: purchaseOrderDetailModifiers,
        }
      )
    );
  };
}

export const initPOFiles = poId => {
  return async dispatch => {
    dispatch(
      initDataComponent(
        poFilesDataComponentId,
        PurchaseOrderFile,
        ["file"],
        "purchase-order-files",
        true,
        "v2"
      )
    );

    dispatch(
      performRetrieveListRequest(poFilesDataComponentId, {
        rootFilters: { $where: { poId } },
        sort: [{ columnName: "key", direction: "asc" }],
        pageSize: -1,
      })
    );
  };
};

export function deletePOFile(poFileId) {
  return performDeleteRequest(poFilesDataComponentId, poFileId);
}

export function uploadPOFiles(files, poId, fileType) {
  return async dispatch => {
    const key = `/purchase-orders/${poId}/${fileType}`;
    const poFiles = await Promise.all(
      Array.from(files).map(async file => {
        const ext = file.name.split(".").pop();
        const filename = `${moment().format(FILE_DATE_FORMAT)}-${
          file.name
        }.${ext}`;
        const s3Key = `${key}/${filename}`;
        await dispatch(uploadFile({ key, filename }, file, s3Key));
        return {
          key: fileType,
          poId,
          file: {
            s3Key,
            filename: file.name,
            metadata: { size: file.size, type: file.type },
          },
        };
      })
    );
    dispatch(performCreateRequest(poFilesDataComponentId, poFiles));
  };
}
