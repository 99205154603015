import React from "react";
import BWModels from "benjaminwest-models";
import _get from "lodash/get";
import PropTypes from "prop-types";

import BoldText from "../../../../ui/Typography/BoldText";
import DateColumn from "../../../../ui/BWGrid/columns/Date";
import { StatusColumn, columnWithEmptyState, MissingIcon } from "./components";
import Price from "../../../../ui/Price";
import { CheckIcon } from "../../../../inputs/inputConfigs/invoice";
import { PRIMARY_DATE_FORMAT } from "../../../../../constants/formats";

const RenderCurrencyValue = (value, currency) => (
  <Price number={value || 0} currency={currency} />
);
RenderCurrencyValue.propTypes = {
  value: PropTypes.number,
};

export const checkColumnRenderer = column =>
  function RenderChekColumn(row) {
    return <CheckIcon isChecked={_get(row, column)} />;
  };

export const columns = [
  "number",
  "paymentRecord",
  "amountRequested",
  "totalPayment",
  "createdAt",
  "lastPaymentDate",
  "method",
  "status",
  "useTaxPayment",
  "other",
];
export const getColumnOptions = () => ({
  number: {
    title: "FR #",
    filter: true,
    width: "80px;",
    render: ({ status, number }) =>
      status && (status === "Void" ? number : <BoldText>{number}</BoldText>),
  },
  paymentRecord: {
    filter: false,
    render: columnWithEmptyState("paymentRecord"),
  },
  amountRequested: {
    width: "140px",
    render: ({ amountRequested, projectCurrency }) =>
      RenderCurrencyValue(amountRequested, projectCurrency?.currency),
  },
  totalPayment: {
    title: "Amount Received",
    width: "140px",
    render: ({ totalPayment, projectCurrency }) =>
      RenderCurrencyValue(totalPayment, projectCurrency?.currency),
  },
  createdAt: {
    width: "120px",
    title: "Date Requested",
    render: ({ createdAt }) =>
      createdAt && <DateColumn date={createdAt} format={PRIMARY_DATE_FORMAT} />,
  },
  lastPaymentDate: {
    title: "Last Payment Date",
    width: "120px",
    render: row => renderLastPaymentDate(row),
  },
  method: {
    filter: false,
    fill: false,
  },
  status: {
    width: "120px",
    align: "right",
    render: StatusColumn,
    filter: "select",
    filterOptions: {
      options: BWModels.loadSchema("Funding").__utils.statuses,
    },
  },
  useTaxPayment: {
    render: checkColumnRenderer("useTaxPayment"),
    align: "right",
    filter: "select",
    filterOptions: {
      options: [
        { id: true, name: "True" },
        { id: false, name: "False" },
      ],
    },
  },
  other: {
    render: checkColumnRenderer("other"),
    align: "right",
    filter: "select",
    filterOptions: {
      options: [
        { id: true, name: "True" },
        { id: false, name: "False" },
      ],
    },
  },
});

const renderLastPaymentDate = row => {
  const fundingPayments = _get(row, "fundingPayments", []);
  let lastPaymentDate =
    fundingPayments.length <= 1
      ? fundingPayments[0]
      : fundingPayments.reduce((lastDate, currentDate) => {
          return new Date(lastDate.receivedAt) >=
            new Date(currentDate.receivedAt)
            ? lastDate
            : currentDate;
        });
  const displaValue = lastPaymentDate ? (
    <DateColumn
      date={lastPaymentDate.receivedAt}
      format={PRIMARY_DATE_FORMAT}
    />
  ) : (
    <MissingIcon />
  );

  return displaValue;
};

export const getRowMenu = ({
  onOpenEditModal,
  onOpenBatchFundingReport,
  isProjectClosed,
}) => row => {
  return [
    {
      text: "Edit",
      onClick: onOpenEditModal,
      disabled:
        row.method !== "Cashflow" || row.status == "Void" || isProjectClosed,
    },
    {
      text: "Print Funding Report → PDF",
      onClick: row => onOpenBatchFundingReport(row, "pdf"),
      disabled: row.method !== "Batch" || row.status == "Void",
    },
    {
      text: "Print Funding Report → CSV",
      onClick: row => onOpenBatchFundingReport(row, "csv"),
      disabled: row.method !== "Batch" || row.status == "Void",
    },
  ];
};
