import { createSelector } from "reselect";
import _noop from "lodash/noop";

import { createDeepEqualSelector } from "../../../utils/selectors";
import { getBluechipResources } from "../../../utils/bluechipUtils";
import { getDataComponentRequestState } from "../../../reducers/dataComponentReducer";
import {
  fetchData,
  initDataComponent,
  performRetrieveListRequest,
  reorder,
  setPage,
  setPageNumberAction,
  setPageSize,
  setReload,
  setSelection,
  setSort,
} from "../../../actions/dataComponentActions";

export const makeMapStateToProps = () => {
  const includesSelector = createDeepEqualSelector(
    (_, { includes }) => includes,
    includes => includes
  );

  const defaultSortingSelector = createDeepEqualSelector(
    (_, { defaultSorting }) => defaultSorting,
    defaultSorting => defaultSorting || [{ columnName: "id", direction: "asc" }]
  );

  const apiFiltersSelector = createDeepEqualSelector(
    (_, { rootFilters }) => rootFilters,
    (_, { apiFilters }) => apiFilters,
    defaultSortingSelector,
    (rootFilters, apiFilters = { rootFilters }) => ({
      ...apiFilters,
    })
  );

  const rowsSelector = createDeepEqualSelector(
    (state, { dataComponent }) => getBluechipResources(dataComponent, state),
    rows => rows
  );
  return (state, ownProps) => {
    const { dataComponent } = ownProps;
    return {
      apiFilters: apiFiltersSelector(state, ownProps),
      includes: includesSelector(state, ownProps),
      defaultSorting: defaultSortingSelector(state, ownProps),
      rootFilters: undefined,
      rows: rowsSelector(state, { dataComponent }),
      requestState: getDataComponentRequestState(
        dataComponent.dataComponentId,
        state
      ),
      pageNumber: dataComponent.pageNumber,
      isLoading: dataComponent.loading,
      reload: dataComponent.reload,
    };
  };
};

export const makeMapDispatchToProps = dispatch => {
  const includesSelector = createDeepEqualSelector(
    (_, { includes }) => includes,
    includes => includes
  );

  const dispatchFunctionsSelector = createSelector(
    (_, { dataComponent }) => dataComponent.dataComponentId,
    (_, { apiRoute }) => apiRoute,
    (_, { model }) => model,
    (_, { onReorder }) => onReorder,
    includesSelector,
    (dataComponentId, apiRoute, model, onReorder = _noop, includes) => {
      return {
        fetchData: () => dispatch(fetchData(dataComponentId)),
        setPage: pageNumber => dispatch(setPage(dataComponentId, pageNumber)),
        setPageNumber: pageNumber =>
          dispatch(setPageNumberAction(dataComponentId, pageNumber)),
        setPageSize: pageSize =>
          dispatch(setPageSize(dataComponentId, pageSize, false)),
        setSort: sort => {
          dispatch(setSort(dataComponentId, sort));
        },
        setSelection: selection =>
          dispatch(setSelection(dataComponentId, selection)),
        performRetrieveListRequest: filters =>
          dispatch(performRetrieveListRequest(dataComponentId, filters)),
        initDataComponent: () =>
          dispatch(
            initDataComponent(dataComponentId, model, includes, apiRoute)
          ),
        setReload: reload => dispatch(setReload(dataComponentId, reload)),
        onReorder: (reorderInfo, item) => {
          dispatch(reorder(dataComponentId, reorderInfo));
          onReorder(reorderInfo, item);
        },
      };
    }
  );
  return (_, ownProps) => {
    return dispatchFunctionsSelector(null, ownProps);
  };
};
