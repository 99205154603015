import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { createSelector } from "reselect";
import { FieldArray } from "formik";
import Joi from "joi-browser";
import shallow from "zustand/shallow";
import _pick from "lodash/pick";
import _keys from "lodash/keys";
import PropTypes from "prop-types";

import BWModels from "benjaminwest-models";

import {
  clearResources,
  initDataComponent,
  performUpdateRequest,
  setReload,
} from "../../../../../actions/dataComponentActions";

import {
  closeModalDialog,
  openModalDialog,
  showSnackNotificationAction,
} from "../../../../../actions/layoutActions";
import { openNotesModal } from "../../../../../actions/notesActions";
import propTypes from "../../../../../constants/propTypes";
import PurchaseOrder from "../../../../../models/PurchaseOrder";
import Shipment from "../../../../../models/Shipment";
import SpecShipment from "../../../../../models/SpecShipment";
import SpecEstimatedDates from "./SpecEstimatedDates";
import useActions from "../../../../hooks/useActions";
import { ActionButtons, FormikForm } from "../../../../forms";

import useShipmentStore from "./useShipmentsStore";
import ManageShipmentsGrid, {
  calcTotalQuantityApprovedToPay,
} from "./ManageShipmentsGrid";
import { SpanError } from "../components";
import { useDatacomponent } from "../../../../hooks/useDatacomponent";
import { useUpdateProcessRequests } from "../../../../hooks/useProcessRequest";
import { specsGridDataComponentId } from "../../../../../actions/purchaseOrderSpecsActions";
import { generateValues } from "../../../Notes/NoteCreate/AssignToSelect/AssignToSelectContainer";
import { subtractVal } from "../../../InvoiceDetail/Specs/SpecsList";

const dataComponentId = "manageShipmentsDataComponent";
const specShipmentsDataComponentId = "SpecShipmentsGrid";

export const parseQuantity = value => Number(value.toFixed(3));

const generateShipments = (resources, specId) => {
  const shipments = Shipment.query(resources)
    .where({ specId })
    .toObjects();

  const spec = SpecShipment.query(resources).find(specId);
  const purchaseOrder = PurchaseOrder.query(resources).find(
    spec.purchaseOrderId
  );

  return shipments.map(shipment => {
    return { ...shipment, spec: { ...spec, purchaseOrder } };
  });
};

const getShipments = createSelector(
  ({ resources }) => resources,
  (_, { specId }) => specId,
  generateShipments
);

const validationSchema = () =>
  Joi.object().keys({
    shipments: Joi.array().items(BWModels.loadSchema("Shipment")),
  });

const totalQuantityErrorMessage = (spec, values) => {
  const shipmentTotalQuantity = parseQuantity(
    values.shipments.reduce(
      (accumulator, shipment) => accumulator + Number(shipment.quantity),
      0
    )
  );

  if (spec.totalQuantityWithoutOverage != shipmentTotalQuantity) {
    return `The shipments' quantity total ${shipmentTotalQuantity} can not be different than the spec's quantity ${spec.totalQuantityWithoutOverage}`;
  }

  return null;
};

const shipmentProperties = {
  quantity: 0,
  trackingNumber: null,
  receivedBy: null,
  estimatedShipDate: null,
  estimatedDeliveryDate: null,
  actualShipDate: null,
  actualDeliveryDate: null,
  notes: null,
  specId: null,
  approvedToPay: null,
  id: null,
};

export const updateApprovedToPay = (
  lastShipmentsData,
  shipments,
  setFieldValue,
  setLastShipmentsData
) => {
  if (!shipments) return;
  shipments.forEach((shipment, index) => {
    const lastActualDeliveryDate = lastShipmentsData[index].actualDeliveryDate;
    if (
      (lastActualDeliveryDate === undefined ||
        lastActualDeliveryDate === null) &&
      shipment.actualDeliveryDate != lastActualDeliveryDate
    ) {
      setLastShipmentsData([...shipments]);
      !shipment.approvedToPay &&
        setFieldValue(`shipments[${index}].approvedToPay`, true);
    }
  });
};

export const handleUpdateRequestError = showSnackNotificationAction => ({
  data,
}) => {
  data.errors.map(error => {
    if (error.global) {
      showSnackNotificationAction(error.title);
    }
  });
};

export const calculateRemainingQuantity = (
  shipments,
  totalQuantity,
  tableRow
) =>
  shipments.reduce(
    (remaining, currentShipment, index) =>
      index == tableRow?.rowId
        ? remaining
        : parseQuantity(subtractVal(remaining, currentShipment.quantity)),
    totalQuantity
  );

// eslint-disable-next-line max-lines-per-function
const ManageShipmentsModal = ({
  spec,
  purchaseOrder,
  shipmentsDataComponentId,
}) => {
  const [quantity, setQuantity] = useState();
  const [left, setLeft] = useState();
  const [shipped, setShipped] = useState();

  const { shipmentsOnDB } = useSelector(state => ({
    shipmentsOnDB: getShipments(state, { specId: spec.id }),
  }));

  const [shipments, shipmentActions] = useShipmentStore(
    state => [state.shipments, state.actions],
    shallow
  );

  const dataComponent = useDatacomponent(dataComponentId);

  const loading = dataComponent.requestState?.update?.loading;

  const actions = useActions({
    openModalDialog,
    setReload,
    openNotesModal,
    closeModalDialog,
    initDataComponent,
    performUpdateRequest,
    showSnackNotificationAction,
    clearResources,
  });

  useUpdateProcessRequests(dataComponent, {
    onSuccess: () => {
      actions.clearResources(["shipments"]);
      actions.closeModalDialog();
      actions.setReload(specShipmentsDataComponentId, true);
      actions.setReload(specsGridDataComponentId, true);
      actions.setReload(shipmentsDataComponentId, true);
      shipmentActions.reset();
    },
    onError: handleUpdateRequestError(actions.showSnackNotificationAction),
  });

  useEffect(() => {
    if (!shipments) {
      shipmentActions.setSpec(spec);
      shipmentActions.setShipments(shipmentsOnDB);
    }
    actions.initDataComponent(
      dataComponentId,
      Shipment,
      [],
      "manage-shipments"
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAddShipment = values => () => {
    const {
      description,
      specId,
      purchaseOrder: { estimatedShipDate, estimatedDeliveryDate },
    } = spec;
    shipmentActions.setShipments(values.shipments);

    const totalQuantity = spec.totalQuantityWithoutOverage;
    const totalQuantityApprovedToPay = calcTotalQuantityApprovedToPay(
      values.shipments
    );

    const remainingQty = calculateRemainingQuantity(
      values.shipments,
      totalQuantity
    );

    actions.openModalDialog(["New Shipment", description], "CreateShipment", {
      specId,
      remainingQty,
      estimatedDeliveryDate,
      estimatedShipDate,
      shipmentActions,
      shipmentsDataComponentId,
      totalQuantityApprovedToPay,
    });
  };

  const handleEditShipment = values => (shipment, tableRow) => {
    const { description } = spec;

    const totalQuantity = (spec.baseQuantity || 0) + (spec.atticStock || 0);
    const totalQuantityApprovedToPay = calcTotalQuantityApprovedToPay(
      values.shipments
    );

    const remainingQty = calculateRemainingQuantity(
      values.shipments,
      totalQuantity,
      tableRow
    );

    shipmentActions.setShipments(values.shipments);

    actions.openModalDialog(
      [`Edit Shipment ${shipment.id} for`, description],
      "EditShipment",
      {
        shipmentId: shipment.id,
        remainingQty,
        localShipment: {
          actualShipDate: `${shipment.actualShipDate}`,
          actualDeliveryDate: `${shipment.actualDeliveryDate}`,
          ...shipment,
        },
        shipmentActions,
        shipmentIndex: tableRow.rowId,
        shipmentsDataComponentId,
        spec,
        totalQuantityApprovedToPay,
      }
    );
  };

  const handleAddTrackingNote = values => shipment => {
    const { id } = shipment;
    shipmentActions.setShipments(values.shipments);
    actions.openModalDialog(
      false,
      "AddTrackingNote",
      {
        shipmentId: id,
        shipment,
        shipmentActions,
        assignTo: generateValues(
          [purchaseOrder],
          [],
          [shipment.spec],
          [shipment]
        ),
        shipmentsDataComponentId,
      },
      false,
      false
    );
  };

  const handleOpenNotesModal = values => shipment => {
    shipmentActions.setShipments(values.shipments);
    actions.openNotesModal(
      {
        projectId: shipment.spec.purchaseOrder.projectId,
        purchaseOrderId: shipment.spec.purchaseOrderId,
      },
      { search: `PO#${shipment.spec.purchaseOrder.number}` },
      () =>
        shipmentActions.openManageShipmentsModal(
          actions.openModalDialog,
          shipmentsDataComponentId
        )
    );
  };

  const handleDeleteShipment = formikArrayHelpers => (row, tableRow) => {
    formikArrayHelpers.remove(tableRow.rowId);
  };

  const handleCancel = () => {
    actions.closeModalDialog();
    shipmentActions.reset();
  };

  const handleSubmit = values => {
    if (totalQuantityErrorMessage(spec, values)) return;

    const shipments = values.shipments.map(shipment => {
      shipment.specId = spec.id;
      return _pick(shipment, _keys(shipmentProperties));
    });

    actions.performUpdateRequest(dataComponentId, shipments, {
      specId: spec.id,
    });
  };

  const rows = shipments || shipmentsOnDB;

  return (
    <React.Fragment>
      <SpecEstimatedDates
        quantity={quantity}
        left={left}
        shipped={shipped}
        spec={spec}
      />
      <FormikForm
        initialValues={{ shipments: rows }}
        validationSchema={validationSchema()}
        onSubmit={handleSubmit}
      >
        {({ handleSubmit, values, ...formikProps }) => {
          return (
            <FieldArray name={"shipments"}>
              {formikArrayHelpers => {
                const errorMessage = totalQuantityErrorMessage(spec, values);
                const spanError =
                  formikProps.status === "submitted" && errorMessage ? (
                    <SpanError>{errorMessage}</SpanError>
                  ) : null;

                return (
                  <React.Fragment>
                    <ManageShipmentsGrid
                      spec={spec}
                      values={values}
                      formikProps={formikProps}
                      formikArrayHelpers={formikArrayHelpers}
                      quantitySetters={{ setQuantity, setLeft, setShipped }}
                      handleAddShipment={handleAddShipment}
                      handleAddTrackingNote={handleAddTrackingNote}
                      handleDeleteShipment={handleDeleteShipment}
                      handleEditShipment={handleEditShipment}
                      handleOpenNotesModal={handleOpenNotesModal}
                    />
                    {spanError}
                    <ActionButtons
                      onSend={handleSubmit}
                      isModal={true}
                      onCancel={handleCancel}
                      loading={loading}
                      additionalProps={{
                        send: { disableIfProjectClosed: true },
                      }}
                    />
                  </React.Fragment>
                );
              }}
            </FieldArray>
          );
        }}
      </FormikForm>
    </React.Fragment>
  );
};

ManageShipmentsModal.propTypes = {
  spec: propTypes.spec,
  purchaseOrder: propTypes.purchaseOrder,
  shipmentsDataComponentId: PropTypes.string,
};

export default ManageShipmentsModal;
