import React, { memo, useEffect, useState } from "react";
import { connect as formikConnect } from "formik";
import PropTypes from "prop-types";
import memoizeOne from "memoize-one";
import _isEqual from "lodash/isEqual";
import { isEmpty } from "lodash";

import { SelectWithError } from "../../../../inputs/SelectWithError";
import PurchaseOrder from "../../../../../models/PurchaseOrder";
import propTypes from "../../../../../constants/propTypes";
import Invoice from "../../../../../models/Invoice";
import Shipment from "../../../../../models/Shipment";
import Spec from "../../../../../models/Spec";
import { usePrevious } from "../../../../hooks/usePrevious";
import {
  clearPORelationsEffect,
  initialValuesEffect,
  noteChangeEffect,
  setRelationsLoadedEffect,
} from "./effects";

const getPurchaseOrderIds = memoizeOne(
  value => value?.filter(({ type }) => type === "PO").map(({ rawId }) => rawId),
  _isEqual
);

const initDataComponents = initDataComponent => () => {
  initDataComponent(
    "select-purchase-orders",
    PurchaseOrder,
    ["vendor"],
    "purchase-orders"
  );
  initDataComponent("select-invoices", Invoice, [], "invoices");
  initDataComponent("select-specs", Spec, [], "specs");
  initDataComponent("select-shipments", Shipment, [], "shipments");
};

const fetchInitialData = (
  performRetrieveListRequest,
  name,
  { values: { projectId }, setFieldValue }
) => () => {
  if (projectId) {
    setFieldValue(name, []);
    performRetrieveListRequest("select-purchase-orders", {
      pageSize: -1,
      rootFilters: {
        $where: {
          "project.id": projectId,
        },
      },
      sort: [{ columnName: "name", direction: "asc" }],
      params: {
        omitDefaultModifier: true,
        modifiers: ["withName"],
      },
    });
  }
};

const fetchResourceData = (
  fetchAssignToRelations,
  purchaseOrderIds,
  isPOsLoaded
) => () => {
  if (purchaseOrderIds && isPOsLoaded) {
    fetchAssignToRelations(purchaseOrderIds);
  }
};

export const AssignToSelect = ({
  memberType,
  onChange,
  initDataComponent,
  fetchAssignToRelations,
  performRetrieveListRequest,
  formik,
  relations,
  search,
  options,
  isLoading,
  isRelationsLoading,
  ...props
}) => {
  const [initialValues, setInitialValues] = useState(
    formik.initialValues.assignTo
  );
  const [itemsRelated] = useState(relations);
  const [isRelationsLoaded, setRelationsLoaded] = useState(false);

  const purchaseOrderIds = getPurchaseOrderIds(formik.values.assignTo);
  const prevRelationsLoading = usePrevious(isRelationsLoading);
  const prevId = usePrevious(formik.values.id);

  const isPOsLoaded =
    !isLoading && (initialValues !== undefined || isEmpty(initialValues?.id));
  const projectId = formik.values.projectId;
  const setFieldValue = formik.setFieldValue;

  useEffect(initDataComponents(initDataComponent), [initDataComponent]);

  useEffect(fetchInitialData(performRetrieveListRequest, props.name, formik), [
    performRetrieveListRequest,
    projectId,
  ]);
  useEffect(
    fetchResourceData(fetchAssignToRelations, purchaseOrderIds, isPOsLoaded),
    [fetchAssignToRelations, purchaseOrderIds]
  );

  useEffect(
    setRelationsLoadedEffect({
      prevRelationsLoading,
      isRelationsLoading,
      isLoading,
      setRelationsLoaded,
      purchaseOrderIds,
    }),
    [isRelationsLoading, prevRelationsLoading]
  );

  useEffect(
    noteChangeEffect({
      setRelationsLoaded,
      setInitialValues,
      id: formik.values.id,
      prevId,
    }),
    [formik.values.id]
  );

  useEffect(
    clearPORelationsEffect({
      formik,
      options,
      setFieldValue,
      name: props.name,
      isRelationsLoaded,
    }),
    [formik.values.assignTo, options, setFieldValue, props.name]
  );

  useEffect(
    initialValuesEffect({
      initialValues: formik.initialValues,
      itemsRelated,
      options,
      setInitialValues,
      isLoading,
      isPOsLoaded,
      isRelationsLoaded,
    }),
    [
      formik.initialValues,
      formik.initialValues.assignTo,
      options,
      itemsRelated,
      setInitialValues,
      isLoading,
    ]
  );

  useEffect(() => {
    setFieldValue(props.name, initialValues);
  }, [setFieldValue, props.name, initialValues]);

  const disabledText = !projectId ? "No project selected" : undefined;

  return (
    <SelectWithError
      {...props}
      options={options}
      onChange={onChange}
      isLoading={isLoading}
      helperText={disabledText}
      isDisabled={props.isDisabled || !!disabledText || isLoading}
    />
  );
};

AssignToSelect.propTypes = {
  initDataComponent: PropTypes.func.isRequired,
  formik: PropTypes.shape({}).isRequired,
  fetchAssignToRelations: PropTypes.func.isRequired,
  performRetrieveListRequest: PropTypes.func.isRequired,
  context: propTypes.notesContext,
  relations: PropTypes.shape({
    po: PropTypes.arrayOf(PropTypes.string),
    ship: PropTypes.arrayOf(PropTypes.string),
    spec: PropTypes.arrayOf(PropTypes.string),
    inv: PropTypes.arrayOf(PropTypes.string),
  }),
};

export default formikConnect(memo(AssignToSelect));
