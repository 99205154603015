import { useCallback, useMemo } from "react";
import { useDispatch } from "react-redux";
import _isEmpty from "lodash/isEmpty";

import { detailsContractGroup } from "./createContractFields";
import { roleCan } from "../../../../utils/roleUtils";
import { getDefaultProjectCurrency } from "../../../helpers";
import * as actionTypes from "../../../../constants/ActionTypes";

const defaultDatePickerDate = new Date("1900-01-01");

export const useGetFields = ({
  client,
  role,
  setFieldValue,
  isUpdate,
  currencies,
  workScopes,
  ...props
}) => {
  const {
    feeBasis,
    isWaiverRequired,
    contractBudget,
    feeAmount,
    feePercent,
    contractFiles,
    projectCurrencies,
    feeScheduleGenerated,
  } = props.values;

  const dispatch = useDispatch();

  const hasContracts = contractFiles.length > 0;

  const defaultCurrency = getDefaultProjectCurrency(
    props.values?.projectCurrencies
  );
  const currency = currencies?.find(
    ({ id }) => id === (defaultCurrency?.currencyId || props.values.currencyId)
  );
  const startDate = props.values.startDate || defaultDatePickerDate;
  const canChangeFeeInformation = roleCan(
    role,
    "project-change-fee-information"
  );
  const canUpdateContractedProjectNameScope =
    !hasContracts || roleCan(role, "update-contracted-project-name-scope");

  const handleCurrencyChange = useCallback(
    projectCurrency => ({ target: { value } }) => {
      if (projectCurrency.isDefault) {
        const selectedCurrency = currencies.find(({ id }) => id == value);
        dispatch({
          type: actionTypes.SET_SCOPED_CURRENCY,
          payload: selectedCurrency,
        });
      }
    },
    [currencies, dispatch]
  );

  const fields = useMemo(
    () =>
      detailsContractGroup(
        {
          ...props.values,
          feeBasis,
          isWaiverRequired,
          startDate,
          feeAmount,
          feePercent,
          contractBudget,
          currency,
          canChangeFeeInformation,
          canUpdateContractedProjectNameScope,
          projectCurrencies,
          currencies,
          canUpdateProjectCurrencies: roleCan(
            role,
            "update-project-currencies"
          ),
          canShowSubScopeNoteToggle: roleCan(
            role,
            "show-sub-scope-note-toggle"
          ),
          canShowEnableFeeSummaryCalculationUpdate: roleCan(
            role,
            "enable-fee-summary-calculation-update"
          ),
          isUpdate,
          feeScheduleGenerated,
          workScopeOptions: workScopes,
          clientId: client?.id,
          handleCurrencyChange,
        },
        updatedCurrencies =>
          setFieldValue("projectCurrencies", updatedCurrencies),
        ({ name }) =>
          name === "Tiered Pricing" && _isEmpty(client?.clientPricings),
        client?.scopeId
      ),
    [
      props.values,
      feeBasis,
      isWaiverRequired,
      startDate,
      feeAmount,
      feePercent,
      contractBudget,
      currency,
      canChangeFeeInformation,
      canUpdateContractedProjectNameScope,
      projectCurrencies,
      currencies,
      role,
      isUpdate,
      feeScheduleGenerated,
      workScopes,
      client,
      handleCurrencyChange,
      setFieldValue,
    ]
  );

  return fields;
};
