import React, { useMemo } from "react";
import AddCircle from "@material-ui/icons/AddCircle";
import PropTypes from "prop-types";
import BWModels from "benjaminwest-models";
import memoizeOne from "memoize-one";
import _get from "lodash/get";
import pluralize from "pluralize";
import _isEmpty from "lodash/isEmpty";

import { CustomCell, CustomRow } from "../../../Specs/SpecsPage";
import { BWGridAPI, GridHeader, GridTable } from "../../../../ui/BWGrid";
import Funding from "../../../../../models/Funding";
import Paper from "../../../../mui/core/Paper";
import { genDetailRowComponents } from "../../../../ui/BWGrid/helperComponents/SpecAdditionalInfoDetailRow";
import { columns, getColumnOptions, getRowMenu } from "./tableOptions";
import {
  openModalDialog,
  showSnackNotificationAction,
} from "../../../../../actions/layoutActions";
import useActions from "../../../../hooks/useActions";
import { useIsProjectClosed } from "../../../../hooks/useIsProjectClosed";

export const isValid = showSnackNotificationAction => ({ other, notes }) => {
  if (other && _isEmpty(notes)) {
    showSnackNotificationAction(
      "Notes cannot be empty when Other checkbox is marked."
    );
    return false;
  }

  return true;
};

const getTableComponents = memoizeOne(
  (dataComponentId, showSnackNotificationAction) => {
    const detailRowComponents = genDetailRowComponents(
      "id",
      [
        {
          path: "notes",
          label: "Notes",
          placeholder: "Add Notes",
          isEmptyFn: Boolean,
        },
      ],
      BWModels.loadSchema("Funding"),
      dataComponentId,
      { prevSpan: 2, colSpan: 8, posSpan: 2 },
      {
        isValid: isValid(showSnackNotificationAction),
      }
    );
    return {
      ...detailRowComponents,
      CellComponent: CustomCell,
      RowComponent: CustomRow,
    };
  }
);

const FundingsList = ({
  dataComponent,
  projectId,
  onOpenCreateModal,
  onOpenPaidConfirmationModal,
  fundingCount,
  onOpenBatchFundingReport,
}) => {
  const columnOptions = useMemo(() => getColumnOptions(), []);
  const actions = useActions({
    showSnackNotificationAction,
    openModalDialog,
  });
  const isProjectClosed = useIsProjectClosed();

  const onOpenEditModal = funding => {
    actions.openModalDialog(
      null,
      "UpdateFunding",
      {
        initialValues: funding,
      },
      false
    );
  };

  return (
    <Paper>
      <BWGridAPI
        dataComponent={dataComponent}
        model={Funding}
        apiRoute="fundings"
        includes={[
          "projectBudgetFile",
          "cashflowFile",
          "fundingPayments",
          "projectCurrency",
        ]}
        defaultSorting={[{ columnName: "number", direction: "asc" }]}
        rootFilters={{
          $where: {
            projectId,
          },
        }}
        tableComponents={getTableComponents(
          dataComponent.dataComponentId,
          actions.showSnackNotificationAction
        )}
        alwaysDisplayDetailRow
        ignoreOptimizations
      >
        <GridHeader
          headerText={`${fundingCount} Funding ${pluralize(
            "Request",
            fundingCount,
            false
          )}`}
          actions={[
            {
              text: "Create funding Request",
              icon: <AddCircle />,
              handler: onOpenCreateModal,
              disableIfProjectClosed: true,
            },
          ]}
        />
        <GridTable
          columns={columns}
          columnOptions={columnOptions}
          onClick={onOpenPaidConfirmationModal}
          disableRow={row => _get(row, "status") === "Void"}
          rowMenu={getRowMenu({
            onOpenEditModal,
            onOpenBatchFundingReport,
            isProjectClosed,
          })}
        />
      </BWGridAPI>
    </Paper>
  );
};
FundingsList.propTypes = {
  projectId: PropTypes.string.isRequired,
  dataComponent: PropTypes.object.isRequired,
  onOpenCreateModal: PropTypes.func.isRequired,
  onOpenPaidConfirmationModal: PropTypes.func.isRequired,
  onOpenBatchFundingReport: PropTypes.func.isRequired,
  fundingCount: PropTypes.number,
};
export default FundingsList;
