import React, { useCallback, useState } from "react";
import styled from "styled-components";

import useActions from "../../../../hooks/useActions";
import { generateAndDownloadReport } from "../../../../../actions/reportsActions";
import propTypes from "../../../../../constants/propTypes";
import DownloadPDFButton from "../../../PurchaseOrderDetail/HeaderSection/DownloadPDFButton";
import Loader from "../../../../ui/Loader";
import Subheader from "../../../../ui/Typography/Subheader";
import { ActionButton } from "../../../../ui/BWActionButton";
import IssuedHistoryDialog from "./IssuedHistoryDialog/IssuedHistoryDialog";

const SectionTitle = styled(Subheader)`
  align-content: center;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 24px 24px 0 24px;
`;

const Actions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const Button = styled(ActionButton)`
  padding: 6px 8px;
`;

const Header = ({ purchaseOrder }) => {
  const [issuedHistoryOpen, setIssuedHistoryOpen] = useState(false);

  const actions = useActions({
    generateAndDownloadReport,
  });

  const handleIssuedHistoryClick = useCallback(() => {
    setIssuedHistoryOpen(true);
  }, []);

  const handleCloseDialog = useCallback(() => {
    setIssuedHistoryOpen(false);
  }, []);

  const handleDownloadPDF = useCallback(
    (params = {}) => {
      actions.generateAndDownloadReport({
        format: "pdf",
        purchaseOrderId: purchaseOrder.id,
        templateName: "purchase-order-detail",
        ...params,
      });
    },
    [actions, purchaseOrder]
  );

  return (
    <Wrapper>
      <SectionTitle>PO Data</SectionTitle>
      <Loader>
        <Actions>
          <DownloadPDFButton
            handleDownloadPDF={handleDownloadPDF}
            label="PO PDF"
          />
          <Button onClick={handleIssuedHistoryClick} text={"Issued History"} />
        </Actions>
      </Loader>
      {issuedHistoryOpen && (
        <IssuedHistoryDialog
          purchaseOrder={purchaseOrder}
          closeDialog={handleCloseDialog}
          handleDownloadPDF={handleDownloadPDF}
        />
      )}
    </Wrapper>
  );
};
Header.propTypes = {
  purchaseOrder: propTypes.purchaseOrder,
};

export default Header;
