import React from "react";

import { createPOFields } from "./createPOFields";
import { LabeledTextContext } from "../../../../withPORevision";
import Header from "./Header";
import { Paper } from "../../../mui/core";
import { FormGrid } from "../../../forms";

const Section = formGridProps => {
  const { discount, purchaseOrder } = formGridProps.initialValues;
  const currency = purchaseOrder?.projectCurrency?.currency;
  return (
    <LabeledTextContext.Provider value={true}>
      <Paper>
        <Header purchaseOrder={purchaseOrder} />
        <FormGrid fields={createPOFields(discount, currency)} />
      </Paper>
    </LabeledTextContext.Provider>
  );
};

Section.propTypes = {};

export default Section;
