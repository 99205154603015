import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  useContext,
} from "react";
import PropTypes from "prop-types";
import { Formik, Form } from "formik";

import AutoSave from "./AutoSave";
import { validateWithJoiSchema } from "../../utils/formValidationUtils";
import { LabeledTextContext } from "../../withPORevision/withLabeledTextOption";
import { useIsProjectClosed } from "../hooks/useIsProjectClosed";

export const AutoSaveContext = React.createContext({ flushAutoSave: null });

const renderAutoSave = (
  isActive,
  props,
  onSubmit,
  noDelayFields,
  debounce,
  delayAutoSave,
  flushAutoSave,
  setFlushAutoSave
) => {
  if (!isActive) return;
  return (
    <AutoSave
      formik={props}
      onSave={onSubmit}
      noDelayFields={noDelayFields}
      debounce={debounce}
      delay={delayAutoSave}
      flushAutoSave={flushAutoSave}
      setFlushAutoSave={setFlushAutoSave}
    />
  );
};

export const validateForm = (
  values,
  validationSchema,
  ignoreValidationFieldNames,
  isPatch
) => {
  const errors = validateWithJoiSchema(values, validationSchema, {
    isPatch,
  });

  // delete keys from errors which match ones we are ignoring
  if (ignoreValidationFieldNames) {
    ignoreValidationFieldNames.forEach(fieldName => {
      for (let key in errors) {
        if (fieldName === key || RegExp(fieldName).test(key)) {
          delete errors[key];
        }
      }
    });
  }

  return errors;
};

const FormikForm = ({
  children,
  validationSchema,
  ignoreValidationFieldNames,
  autoSave,
  onSubmit,
  allowPartialValidation,
  initialValues,
  ignoreCache,
  valueOverrides,
  noDelayFields,
  debounce,
  delayAutoSave,
  ...props
}) => {
  const [cachedInitialValues, setCachedInitialValues] = useState(initialValues);
  const [cachedNoDelayField] = useState(noDelayFields);
  const [flushAutoSave, setFlushAutoSave] = useState(null);

  useEffect(() => {
    if (!initialValues) return;

    if (ignoreCache) {
      return setCachedInitialValues(initialValues);
    }

    if (initialValues.id !== cachedInitialValues.id) {
      setCachedInitialValues(initialValues);
    }
  }, [initialValues, ignoreCache, cachedInitialValues.id]);

  const values = useMemo(() => {
    return {
      ...cachedInitialValues,
      ...valueOverrides,
    };
  }, [cachedInitialValues, valueOverrides]);

  const renderTest = useCallback(
    formikProps => {
      const handleSubmit = (...args) => {
        formikProps.setStatus("submitted");
        formikProps.handleSubmit(...args);
      };

      return (
        <AutoSaveContext.Provider value={{ flushAutoSave }}>
          <Form>
            {children({
              ...formikProps,
              handleSubmit,
            })}
            {renderAutoSave(
              autoSave,
              formikProps,
              onSubmit,
              cachedNoDelayField,
              debounce,
              delayAutoSave,
              flushAutoSave,
              setFlushAutoSave
            )}
          </Form>
        </AutoSaveContext.Provider>
      );
    },
    [
      children,
      autoSave,
      onSubmit,
      cachedNoDelayField,
      debounce,
      delayAutoSave,
      flushAutoSave,
    ]
  );
  const validate = values =>
    validateForm(
      values,
      validationSchema,
      ignoreValidationFieldNames,
      autoSave || allowPartialValidation
    );

  const isProjectClosed = useIsProjectClosed();
  const isLabeled = useContext(LabeledTextContext) || isProjectClosed;

  return (
    <LabeledTextContext.Provider value={isLabeled}>
      <Formik
        onSubmit={onSubmit}
        validate={validate}
        {...props}
        initialValues={values}
      >
        {props => renderTest(props)}
      </Formik>
    </LabeledTextContext.Provider>
  );
};

FormikForm.propTypes = {
  initialValues: PropTypes.oneOfType([
    PropTypes.shape({}),
    PropTypes.arrayOf(PropTypes.shape({})),
  ]),
  overrides: PropTypes.object,
  onSubmit: PropTypes.func,
  validationSchema: PropTypes.object,
  autoSave: PropTypes.bool,
  allowPartialValidation: PropTypes.bool,
  enableReinitialize: PropTypes.bool,
  ignoreCache: PropTypes.bool,
  ignoreValidationFieldNames: PropTypes.array,
  noDelayFields: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(RegExp)])
  ),
  debounce: PropTypes.number,
  delayAutoSave: PropTypes.number,
};

FormikForm.defaultProps = {
  initialValues: {},
  valueOverrides: {},
  delayAutoSave: 1500,
};

export default FormikForm;
