import React, { Fragment, useMemo, useState } from "react";
import PropTypes from "prop-types";
import BWModels from "benjaminwest-models";
import styled from "styled-components";
import { Divider } from "@material-ui/core";

import { amountRequestedFields, noteField } from "./fundingFields";
import FormGrid from "../../../../../../components/forms/FormGrid";
import ActionButtons from "../../../../../../components/forms/ActionButtons";
import FormikForm from "../../../../../../components/forms/FormikForm";
import propTypes from "../../../../../../constants/propTypes";

import { useV2DatacomponentResources } from "../../../../../hooks/useResources";
import * as RequestTypes from "../../../../../../constants/RequestTypes";
import { cleanCurrencyValue } from "../../../../../../utils/currencyFormatter";
import { FundingFormTitle } from "./FundingFormTitle";
import { CashflowDocuments } from "./CashflowDocuments";

const Content = styled.div`
  overflow-y: auto;
  max-height: 58vh;
`;

const updateByProp = (values, setFieldValue, prop) => {
  if (!isNaN(values[prop])) {
    setFieldValue(`${prop}Local`, cleanCurrencyValue(values[prop]));
  }
};

export const handleFundingCurrencyChange = (
  values,
  setFieldValue,
  projectCurrencies,
  setChangingCurrency
) => event => {
  const value = event.target.value || 0;
  const selectedProjectCurrency = projectCurrencies.find(
    projectCurrency => projectCurrency.id == value
  );

  setChangingCurrency(true);

  const props = ["amountRequested"];
  props.forEach(prop => {
    updateByProp(values, setFieldValue, prop);
  });

  setFieldValue("projectCurrency", selectedProjectCurrency);

  setChangingCurrency(false);
};

export const handleCurrencyInputChange = (
  setFieldValue,
  selectedProjectCurrency,
  changingCurrency,
  setChangingCurrency
) => (event, fieldName) => {
  if (!selectedProjectCurrency) return;
  if (changingCurrency) {
    setChangingCurrency(false);
    return;
  }
  const value = event.target.value || 0;

  const newFieldNameValue = cleanCurrencyValue(value);

  setFieldValue(fieldName, newFieldNameValue);
};

const FundingForm = ({
  onSubmit,
  initialValues,
  dataComponentId,
  loading,
  hideCancelButton,
  onCancel,
  sendButtonText,
  closeModalDialog,
  isFundingPaidConfirmation,
}) => {
  const modifiedSchema = useMemo(
    () =>
      BWModels.loadSchema("Funding").append({
        cashflowFile: BWModels.Joi.when("method", {
          is: "Cashflow",
          then: BWModels.loadSchema("File").required(),
          otherwise: BWModels.loadSchema("File"),
        }),
        projectBudgetFile: BWModels.Joi.when("method", {
          is: "Cashflow",
          then: BWModels.loadSchema("File").required(),
          otherwise: BWModels.loadSchema("File"),
        }),
      }),
    []
  );

  const projectCurrencies = useV2DatacomponentResources(
    "select-project-currencies",
    [],
    RequestTypes.LIST
  );

  const [changingCurrency, setChangingCurrency] = useState(false);

  return (
    <FormikForm
      initialValues={initialValues}
      onSubmit={onSubmit}
      ignoreValidationFieldNames={[
        "type",
        "processingDate",
        "fundsReceived",
        "paymentRecord",
      ]}
      validationSchema={modifiedSchema}
    >
      {({ handleSubmit, values, errors, setFieldValue, ...formikProps }) => {
        const selectedProjectCurrency = projectCurrencies.find(
          projectCurrency => projectCurrency.id == values.projectCurrencyId
        );
        return (
          <Fragment>
            <FundingFormTitle
              closeModalDialog={closeModalDialog}
              isFundingPaidConfirmation={isFundingPaidConfirmation}
            />
            <Content>
              <FormGrid
                fields={amountRequestedFields(
                  {
                    ...values,
                    selectedProjectCurrency,
                  },
                  handleFundingCurrencyChange(
                    values,
                    setFieldValue,
                    projectCurrencies,
                    setChangingCurrency
                  ),
                  handleCurrencyInputChange(
                    setFieldValue,
                    selectedProjectCurrency,
                    changingCurrency,
                    setChangingCurrency
                  )
                )}
                values={values}
                errors={errors}
                {...formikProps}
              />
              <CashflowDocuments />
              <Divider />
              <FormGrid
                fields={noteField}
                values={values}
                errors={errors}
                {...formikProps}
              />
              {onSubmit && (
                <ActionButtons
                  onSend={handleSubmit}
                  isModal={true}
                  sendButtonText={sendButtonText}
                  listeners={[dataComponentId]}
                  hideCancelButton={loading || hideCancelButton}
                  onCancel={onCancel}
                />
              )}
            </Content>
          </Fragment>
        );
      }}
    </FormikForm>
  );
};

FundingForm.propTypes = {
  onSubmit: PropTypes.func,
  isUpdate: PropTypes.bool,
  initialValues: propTypes.glCode,
  isFundingPaidConfirmation: PropTypes.bool,
};

FundingForm.defaultProps = {
  sendButtonText: "Issue Funding Request",
};

export default FundingForm;
