import React, { Fragment, useEffect } from "react";
import PropTypes from "prop-types";
import BWModels from "benjaminwest-models";
import styled from "styled-components";
import pick from "lodash.pick";
import { Divider } from "@material-ui/core";

import { noteField } from "../CreateFunding/fundingFields";
import FormGrid from "../../../../../../components/forms/FormGrid";
import ActionButtons from "../../../../../../components/forms/ActionButtons";
import FormikForm from "../../../../../../components/forms/FormikForm";
import propTypes from "../../../../../../constants/propTypes";

import { SubTitle } from "../CreateFunding/SubTitle";
import ModalTitle from "../../../../../layout/AppLayout/ModalDialog/ModalTitle";
import { useIsProjectClosed } from "../../../../../hooks/useIsProjectClosed";
import useActions from "../../../../../hooks/useActions";
import {
  initDataComponent,
  performUpdateRequest,
} from "../../../../../../actions/dataComponentActions";
import Funding from "../../../../../../models/Funding";
import * as RequestTypes from "../../../../../../constants/RequestTypes";
import { useUpdateProcessRequests } from "../../../../../hooks/useProcessRequest";
import { useDatacomponent } from "../../../../../hooks/useDatacomponent";
import { showSnackNotificationAction } from "../../../../../../actions/layoutActions";
import { useV2DatacomponentResources } from "../../../../../hooks/useResources";
import { amountRequestedFields } from "./fields";

const Content = styled.div`
  overflow-y: auto;
  max-height: 58vh;
`;

const dataComponentId = "update-funding";

const UpdateFunding = ({ initialValues, onCancel, closeModalDialog }) => {
  const isProjectClosed = useIsProjectClosed();

  const actions = useActions({
    initDataComponent,
    performUpdateRequest,
    closeModalDialog,
    showSnackNotificationAction,
  });

  useEffect(() => {
    actions.initDataComponent(dataComponentId, Funding, [], "fundings");
  }, [actions]);

  const dataComponent = useDatacomponent(dataComponentId);
  useUpdateProcessRequests(dataComponent, {
    onSuccess: () => {
      actions.closeModalDialog();
      actions.showSnackNotificationAction(
        "Funding has been updated successfully."
      );
    },
  });

  const handleSubmit = values => {
    actions.performUpdateRequest(
      dataComponentId,
      initialValues.id,
      pick(values, ["id", "useTaxPayment", "other", "notes"])
    );
  };

  const projectCurrencies = useV2DatacomponentResources(
    "select-project-currencies",
    [],
    RequestTypes.LIST
  );

  return (
    <FormikForm
      initialValues={initialValues}
      onSubmit={handleSubmit}
      ignoreValidationFieldNames={[
        "type",
        "processingDate",
        "fundsReceived",
        "paymentRecord",
      ]}
      validationSchema={BWModels.loadSchema("Funding")}
    >
      {({ handleSubmit, values, errors, setFieldValue, ...formikProps }) => {
        const selectedProjectCurrency = projectCurrencies.find(
          projectCurrency => projectCurrency.id == values.projectCurrencyId
        );
        return (
          <Fragment>
            <ModalTitle
              title={`Update Funding Request #${values.number}`}
              extraTitle={<SubTitle />}
              isShowCloseIcon={true}
              closeModalDialog={closeModalDialog}
              width="100%"
            />
            <Content>
              <FormGrid
                fields={amountRequestedFields({
                  ...values,
                  selectedProjectCurrency,
                })}
                values={values}
                errors={errors}
                {...formikProps}
              />
              <Divider />
              <FormGrid
                fields={noteField}
                values={values}
                errors={errors}
                {...formikProps}
              />
              <ActionButtons
                onSend={handleSubmit}
                isModal={true}
                listeners={[dataComponentId]}
                disabled={isProjectClosed}
                onCancel={onCancel}
              />
            </Content>
          </Fragment>
        );
      }}
    </FormikForm>
  );
};

UpdateFunding.propTypes = {
  onSubmit: PropTypes.func,
  initialValues: propTypes.funding,
};

UpdateFunding.defaultProps = {
  sendButtonText: "Save",
};

export default UpdateFunding;
