import React from "react";

import { Input } from "./components";
import PropTypes from "prop-types";

const TextArea = ({ placeholder, id, value, disabled, onChange, ...props }) => {
  return (
    <Input
      placeholder={placeholder}
      id={id}
      value={value || ""}
      disabled={disabled}
      title={value}
      onChange={onChange}
      {...props}
    />
  );
};

TextArea.propTypes = {
  value: PropTypes.string,
  inputComponentProps: PropTypes.shape({}),
  id: PropTypes.string,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
};

TextArea.defaultProps = {
  inputComponentProps: {},
};

export default TextArea;
