import React, { useMemo } from "react";
import _orderBy from "lodash/orderBy";
import { Dialog } from "@material-ui/core";
import styled from "styled-components";
import PropTypes from "prop-types";

import { usePerformRetrieveList } from "../../../../../hooks/usePerformRetrieveList";
import Revision from "../../../../../../models/Revision";
import IssuedHistory from "../../../../PurchaseOrderDetail/IssuedHistory";
import propTypes from "../../../../../../constants/propTypes";
import ModalTitle from "../../../../../layout/AppLayout/ModalDialog/ModalTitle";

const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 730px;
`;

const dataComponent = {
  dataComponentId: "purchase-order-revisions",
  model: Revision,
  includes: [],
  apiRoute: "revisions",
};

const IssuedHistoryDialog = ({
  purchaseOrder,
  closeDialog,
  handleDownloadPDF,
}) => {
  const filters = useMemo(
    () => ({
      pageSize: -1,
      sort: [{ columnName: "version", direction: "desc" }],
      rootFilters: {
        $where: {
          purchaseOrderId: purchaseOrder.id,
        },
      },
    }),
    [purchaseOrder.id]
  );

  const { isLoading, data: revisions } = usePerformRetrieveList(
    dataComponent,
    filters
  );

  const visibleRevisions = useMemo(
    () =>
      _orderBy(revisions, "version", "desc").filter(
        revision => !!revision.issueDate
      ),
    [revisions]
  );

  return (
    <Dialog
      open={true}
      PaperProps={{
        elevation: 0,
        style: { backgroundColor: "transparent", display: "contents" },
      }}
      disablePortal
      style={{ zIndex: 1299 }}
    >
      <Content>
        <ModalTitle
          title={`PO#${purchaseOrder.number} - Issued History`}
          closeModalDialog={closeDialog}
          width={"730px"}
          isShowCloseIcon
        />
        <IssuedHistory
          purchaseOrder={purchaseOrder}
          handleDownloadPDF={handleDownloadPDF}
          revisions={visibleRevisions}
          loading={isLoading}
          pageSectionProps={{ noBottomMargin: true, headerText: null }}
          noMarginTop
        />
      </Content>
    </Dialog>
  );
};
IssuedHistoryDialog.propTypes = {
  purchaseOrder: propTypes.purchaseOrder,
  closeDialog: PropTypes.func.isRequired,
  handleDownloadPDF: PropTypes.func.isRequired,
};
export default IssuedHistoryDialog;
