import { useSelector } from "react-redux";

import { getDataComponentFlattenedRequestState } from "../../reducers/dataComponentReducer";
import { getBluechipResources } from "../../utils/bluechipUtils";
import { getSpecShipmentsDataComponentId } from "../pages/ProjectDetail/Shipments/SpecShipments/SpecShipmentsContainer";

const isPODelivered = (
  { shipmentsCount, shipmentsWithoutDelivery },
  specShipments
) =>
  (shipmentsCount > 0 && shipmentsWithoutDelivery == 0) ||
  (specShipments.some(({ shipmentsCount }) => shipmentsCount > 0) &&
    specShipments.every(
      ({ shipmentsWithoutDelivery }) => shipmentsWithoutDelivery == 0
    ));

export const usePODelivered = purchaseOrder => {
  const specShipmentsDataComponentId = getSpecShipmentsDataComponentId(
    purchaseOrder
  );
  const { specShipments, isLoading } = useSelector(state => {
    const flattenedDataComponent = getDataComponentFlattenedRequestState(
      specShipmentsDataComponentId,
      state
    );
    return {
      flattenedDataComponent,
      specShipments: getBluechipResources(flattenedDataComponent, state) || [],
      isLoading: flattenedDataComponent.loading,
    };
  });
  return {
    isDelivered: isPODelivered(purchaseOrder, specShipments),
    isLoading,
  };
};
