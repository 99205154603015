import { inputs } from "../../../../../../components/inputs/inputConfigs";

export const amountRequestedFields = ({
  projectId,
  selectedProjectCurrency,
}) => {
  return {
    groups: [
      {
        items: [
          {
            input: {
              ...inputs.projectCurrency,
              isDisabled: true,
              APIOptions: {
                ...inputs.projectCurrency.APIOptions,
                rootFilters: {
                  $where: { projectId },
                },
              },
            },
            grid: { xs: 6 },
          },
          {
            input: {
              ...inputs.amountRequested,
              disabled: true,
              inputProps: {
                maskProps: { allowNegative: true },
                currency: selectedProjectCurrency?.currency,
              },
            },
            grid: { xs: 6 },
          },
        ],
      },
    ],
  };
};
