import React from "react";
import PropTypes from "prop-types";
import propTypes from "../../../../../constants/propTypes";

import DetailComponent from "./DetailComponent";
import { Cell } from "../EditableTextDetailRow/components";
import styled from "styled-components";

const column = {};

export const CustomRow = props => {
  return <StyledRow {...props} />;
};
const StyledRow = styled.tr`
  background-color: ${({ isIncomplete }) =>
    isIncomplete ? "rgba(0, 0, 0, 0.05) !important" : "initial !important"};

  td {
    height: 27px;
  }
`;
/**
 *
 * @param idPath
 * @param rowsSettings
 * @param validationSchema
 * @param dataComponentId
 * @param colSettings
 * @return {{DetailRowComponent: (function(*): *), WrapperDetailRow: WrapperDetailRow, RowComponent, DetailCellComponent: (function({children: *}): *)}}
 */
export const genDetailRowComponents = (
  idPath,
  rowsSettings,
  validationSchema,
  dataComponentId,
  colSettings = {},
  detailComponentProps = {}
) => {
  const DetailRowComponent = props => (
    <DetailComponent
      idPath={idPath}
      rowsSettings={rowsSettings}
      validationSchema={validationSchema}
      dataComponentId={dataComponentId}
      {...props}
      {...detailComponentProps}
    />
  );
  const WrapperDetailRow = props => {
    if (!colSettings.colSpan)
      return (
        <CustomRow isIncomplete={props.row.isIncomplete}>
          {props.children}
        </CustomRow>
      );
    const { prevSpan, posSpan } = colSettings;
    return (
      <CustomRow isIncomplete={props.row.isIncomplete}>
        <Cell column={column} colSpan={prevSpan} />
        {props.children}
        {posSpan > 0 && <Cell column={column} colSpan={posSpan} />}
      </CustomRow>
    );
  };

  WrapperDetailRow.propTypes = {
    children: PropTypes.arrayOf(PropTypes.shape({})),
    row: PropTypes.oneOfType([
      propTypes.spec,
      PropTypes.shape({ id: PropTypes.number }),
    ]),
  };

  const DetailCellComponent = ({ children }) => (
    <Cell column={column} colSpan={colSettings.colSpan}>
      {children}
    </Cell>
  );

  DetailCellComponent.propTypes = {
    children: PropTypes.shape({}),
  };

  return {
    DetailRowComponent,
    RowComponent: CustomRow,
    WrapperDetailRow,
    DetailCellComponent,
  };
};
