import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import shallow from "zustand/shallow";

import { getDataComponentFlattenedRequestState } from "../../../../../reducers/dataComponentReducer";
import {
  openModalDialog,
  closeModalDialog,
  setAutoSaveComponentId,
} from "../../../../../actions/layoutActions";
import {
  initDataComponent,
  performRetrieveListRequest,
  performUpdateRequest,
  setReload,
} from "../../../../../actions/dataComponentActions";
import { initNotesModal } from "../../../../../actions/notesActions";

import SpecShipment from "../../../../../models/SpecShipment";
import Spec from "../../../../../models/Spec";
import useActions from "../../../../hooks/useActions";
import propTypes from "../../../../../constants/propTypes";
import useShipmentStore from "../../Shipments/ManageShipments/useShipmentsStore";
import { getRootFilters } from "../../Shipments/gridProps";
import SpecShipmentsGrid from "./SpecShipmentsGrid";
import {
  handleOpenEditAction,
  handleOpenEditAllAction,
  handleOpenManageShipments,
} from "../../Shipments/ShipmentsContainer";
import { getBluechipResources } from "../../../../../utils/bluechipUtils";
import Shipment from "../../../../../models/Shipment";
import { getOutdatedShipment } from "./gridProps";
import { useDatacomponent } from "../../../../hooks/useDatacomponent";
import { useUpdateProcessRequests } from "../../../../hooks/useProcessRequest";
import { dataComponentId } from "../../../PurchaseOrderDetail/Specs/SpecsContainer";
import { generateValues } from "../../../Notes/NoteCreate/AssignToSelect/AssignToSelectContainer";

export const getSpecShipmentsDataComponentId = purchaseOrder =>
  `Expediting-Spec-Shipments-${purchaseOrder.id}`;
export const getShipmentsDataComponentId = purchaseOrder =>
  `Expediting-Shipments-${purchaseOrder.id}`;
export const getSpecsDataComponentId = purchaseOrder =>
  `Spec-${purchaseOrder.id}`;

const buildOnSuccess = ({
  setReload,
  specShipmentsDataComponentId,
  shipmentsDataComponentId,
}) => () => {
  setReload(specShipmentsDataComponentId, true);
  setReload(shipmentsDataComponentId, true);
  setReload(dataComponentId, true);
};

export const initSpeShipmentDataComponents = (
  initDataComponent,
  purchaseOrderId
) => {
  const purchaseOrder = { id: purchaseOrderId };
  const specShipmentsDataComponentId = getSpecShipmentsDataComponentId(
    purchaseOrder
  );
  const shipmentsDataComponentId = getShipmentsDataComponentId(purchaseOrder);
  const specsDataComponentId = getSpecsDataComponentId(purchaseOrder);

  initDataComponent(
    specShipmentsDataComponentId,
    SpecShipment,
    ["purchaseOrder.vendor", "shipments(withNumber)", "area", "unitOfMeasure"],
    "spec-shipments",
    true
  );
  initDataComponent(shipmentsDataComponentId, Shipment, [], "shipments", true);
  initDataComponent(specsDataComponentId, Spec, [], "specs", true);
};

// eslint-disable-next-line max-lines-per-function
export const SpecShipmentsContainer = ({ purchaseOrder, ...props }) => {
  const specShipmentsDataComponentId = getSpecShipmentsDataComponentId(
    purchaseOrder
  );
  const shipmentsDataComponentId = getShipmentsDataComponentId(purchaseOrder);
  const specsDataComponentId = getSpecsDataComponentId(purchaseOrder);

  const { projectId } = useParams();

  const actions = useActions({
    openModalDialog,
    closeModalDialog,
    initDataComponent,
    performRetrieveListRequest,
    performUpdateRequest,
    setReload,
    setAutoSaveComponentId,
    initNotesModal,
  });

  const { flattenedDataComponent, specShipments, isLoading } = useSelector(
    state => {
      const flattenedDataComponent = getDataComponentFlattenedRequestState(
        specShipmentsDataComponentId,
        state
      );
      return {
        flattenedDataComponent,
        specShipments:
          getBluechipResources(flattenedDataComponent, state) || [],
        isLoading: flattenedDataComponent.loading,
      };
    }
  );

  useEffect(() => {
    initSpeShipmentDataComponents(actions.initDataComponent, purchaseOrder.id);
    actions.performRetrieveListRequest(specShipmentsDataComponentId, {
      rootFilters: getRootFilters(projectId, purchaseOrder.id),
      sort: [{ columnName: "customNumber", direction: "asc" }],
      pageSize: -1,
      params: {
        omitDefaultModifier: true,
        modifiers: ["withTotalQuantityPaid", "withTotalQuantityApprovedToPay"],
      },
    });
  }, [
    actions,
    projectId,
    purchaseOrder.id,
    shipmentsDataComponentId,
    specShipmentsDataComponentId,
    specsDataComponentId,
  ]);

  useEffect(() => {
    if (flattenedDataComponent.reload) {
      actions.performRetrieveListRequest(specShipmentsDataComponentId, {
        rootFilters: getRootFilters(projectId, purchaseOrder.id),
        sort: [{ columnName: "customNumber", direction: "asc" }],
        pageSize: -1,
      });
      actions.setReload(specShipmentsDataComponentId, false);
    }
  }, [
    actions,
    flattenedDataComponent.reload,
    projectId,
    purchaseOrder.id,
    specShipmentsDataComponentId,
  ]);

  const shipmentsDataComponent = useDatacomponent(shipmentsDataComponentId);
  const specsDataComponent = useDatacomponent(specsDataComponentId);
  const onSuccess = buildOnSuccess({
    setReload: actions.setReload,
    specShipmentsDataComponentId,
    shipmentsDataComponentId,
  });
  useUpdateProcessRequests(shipmentsDataComponent, {
    onSuccess,
  });
  useUpdateProcessRequests(specsDataComponent, {
    onSuccess,
  });

  const [shipmentActions] = useShipmentStore(state => [state.actions], shallow);

  specShipments.forEach(spec => {
    spec.shipment = getOutdatedShipment(spec.shipments);
  });

  const handleApproveToPay = (selectedShipments, approvedToPay = true) => {
    const newShipments = selectedShipments.map(({ id }) => ({
      id,
      approvedToPay,
    }));
    actions.setAutoSaveComponentId(shipmentsDataComponentId);
    actions.performUpdateRequest(shipmentsDataComponentId, newShipments);
  };

  const handleSpecUpdate = (selectedSpecs, patchedSpec) => {
    const newSpecs = selectedSpecs.map(({ id }) => ({
      id,
      ...patchedSpec,
    }));
    actions.setAutoSaveComponentId(specsDataComponentId);
    actions.performUpdateRequest(specsDataComponentId, newSpecs);
  };

  const handleAddTrackingNote = selectedSpecShipments => {
    actions.openModalDialog(
      false,
      "AddTrackingNote",
      {
        shipmentActions,
        projectId,
        assignTo: generateValues([purchaseOrder], [], selectedSpecShipments),
      },
      false,
      false
    );
  };

  const handleStatusComment = selectedSpecShipments => {
    actions.openModalDialog(
      "Set Status Comment",
      "SetStatusComment",
      {
        selectedSpecShipments,
        parentDataComponentId: specShipmentsDataComponentId,
      },
      false,
      false
    );
  };

  const handleStatusCommentAll = () => {
    actions.openModalDialog(
      "Set Status Comment (All)",
      "SetStatusCommentAll",
      {
        purchaseOrderId: purchaseOrder.id,
        parentDataComponentId: specShipmentsDataComponentId,
      },
      false,
      false
    );
  };

  return (
    <SpecShipmentsGrid
      specShipments={specShipments}
      dataComponentId={specShipmentsDataComponentId}
      paginationGridId={specShipmentsDataComponentId}
      shipmentsDataComponentId={shipmentsDataComponentId}
      isLoading={isLoading}
      alwaysDisplayDetailRow={!isLoading}
      onClickSpec={handleOpenManageShipments({
        openModalDialog: actions.openModalDialog,
        closeModalDialog: actions.closeModalDialog,
        reset: shipmentActions.reset,
        props: {
          shipmentsDataComponentId: specShipmentsDataComponentId,
          purchaseOrder,
        },
      })}
      onOpenEditAction={handleOpenEditAction({
        openModalDialog: actions.openModalDialog,
        props: {
          shipmentsDataComponentId: specShipmentsDataComponentId,
        },
      })}
      onOpenEditAllAction={handleOpenEditAllAction({
        openModalDialog: actions.openModalDialog,
        purchaseOrder,
        props: {
          shipmentsDataComponentId: specShipmentsDataComponentId,
        },
      })}
      handleApproveToPay={handleApproveToPay}
      handleAddTrackingNote={handleAddTrackingNote}
      handleStatusComment={handleStatusComment}
      handleStatusCommentAll={handleStatusCommentAll}
      handleSpecUpdate={handleSpecUpdate}
      purchaseOrder={purchaseOrder}
      {...props}
    />
  );
};

SpecShipmentsContainer.propTypes = {
  purchaseOrder: propTypes.purchaseOrder,
};

export default SpecShipmentsContainer;
