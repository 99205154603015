import PropTypes from "prop-types";
import React from "react";
import _get from "lodash/get";

import TextArea from "./inputComponents/TextArea";
import RegularText from "../../../../../ui/Typography/RegularText";
import { InputWrapper, LabelWrapper, LabelArrow } from "./components";

const Input = ({
  values,
  itemId,
  path,
  label,
  disabled,
  placeholder,
  alwaysVisible,
  isEmptyFn,
  inputComponentProps,
  InputComponent,
  onChangeValue,
  showLabel,
  ...props
}) => {
  const id = `detail-spec-${itemId}-${path}-input`;
  const value = _get(values, path);
  if (isEmptyFn(value) && !alwaysVisible) {
    return null;
  }

  const handleChange = evt => {
    onChangeValue(path, evt.target.value);
  };

  return (
    <InputWrapper>
      <LabelWrapper disabled={disabled} htmlFor={id}>
        <LabelArrow />
        {showLabel && <RegularText intent="black">{label}</RegularText>}
        {showLabel && <RegularText intent="light">&nbsp;-&nbsp;</RegularText>}
      </LabelWrapper>
      <InputComponent
        placeholder={placeholder}
        id={id}
        value={value}
        inputComponentProps={inputComponentProps}
        disabled={disabled}
        onChange={handleChange}
        {...props}
      />
    </InputWrapper>
  );
};

Input.propTypes = {
  values: PropTypes.shape({}),
  inputComponentProps: PropTypes.shape({}),
  path: PropTypes.string,
  itemId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.string,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  alwaysVisible: PropTypes.bool,
  InputComponent: PropTypes.func,
  isEmptyFn: PropTypes.func,
  onChangeValue: PropTypes.func,
  showLabel: PropTypes.bool,
};

Input.defaultProps = {
  disabled: false,
  alwaysVisible: true,
  isEmptyFn: Boolean,
  showLabel: true,
  InputComponent: TextArea,
};

export default Input;
