import React, { useEffect } from "react";
import PropTypes from "prop-types";

import { closeModalDialog } from "../../../../actions/layoutActions";
import {
  initDataComponent,
  performUpdateRequest,
  setReload,
} from "../../../../actions/dataComponentActions";
import { ActionButtons, FormGrid, FormikForm } from "../../../forms";
import { inputs } from "../../../inputs/inputConfigs";
import useActions from "../../../hooks/useActions";
import Spec from "../../../../models/Spec";
import CheckboxWithError from "../../../inputs/CheckboxWithError";
import { FormTitle } from "../../ProjectDetail/Shipments/EditMultipleShipments/EditMultipleShipmentsModal";
import { useBluechipResources } from "../../../hooks/useResources";
import { useUpdateProcessRequests } from "../../../hooks/useProcessRequest";
import { useDatacomponent } from "../../../hooks/useDatacomponent";
import { dataComponentId } from "../specContainerFunctions";

const fields = {
  groups: [
    {
      items: [
        {
          input: {
            ...inputs.statusComment,
            name: "forecastComment",
            label: "Forecast Comment",
            avoidDebounce: true,
            avoidOnChange: false,
          },
          grid: { xs: 12 },
        },
        {
          input: {
            name: "overwriteComments",
            InputComponent: CheckboxWithError,
            label: "Overwrite Existing Comments",
          },
          grid: { xs: 12 },
        },
      ],
    },
  ],
};

export const specDataComponentId = "Spec-Forecast-Comment";

const SetForecastComment = ({ selectedIds }) => {
  const actions = useActions({
    initDataComponent,
    closeModalDialog,
    performUpdateRequest,
    setReload,
  });

  const specs = useBluechipResources(Spec, [], selectedIds);

  useEffect(() => {
    actions.initDataComponent(specDataComponentId, Spec, [], "specs", true);
  }, [actions]);

  const submit = ({ forecastComment, overwriteComments }) => {
    actions.performUpdateRequest(
      specDataComponentId,
      specs
        .filter(({ forecastComment }) => overwriteComments || !forecastComment)
        .map(({ id }) => ({ id, forecastComment }))
    );
  };

  const dataComponent = useDatacomponent(specDataComponentId);
  useUpdateProcessRequests(dataComponent, {
    onSuccess: () => {
      actions.setReload(dataComponentId, true);
      actions.closeModalDialog();
    },
  });

  return (
    <React.Fragment>
      <FormikForm
        initialValues={{ forecastComment: "", overwriteComments: false }}
        onSubmit={submit}
      >
        {({ handleSubmit, values, errors, ...formikProps }) => {
          return (
            <React.Fragment>
              <FormGrid
                fields={fields}
                values={values}
                errors={errors}
                {...formikProps}
              />
              {!values.overwriteComments && (
                <FormTitle>
                  All Selected Specs which do not have comments will be updated
                </FormTitle>
              )}
              <ActionButtons
                onSend={handleSubmit}
                isModal={true}
                listeners={[specDataComponentId]}
                disabled={!values.forecastComment}
              />
            </React.Fragment>
          );
        }}
      </FormikForm>
    </React.Fragment>
  );
};

SetForecastComment.propTypes = {
  selectedIds: PropTypes.array.isRequired,
};

export default SetForecastComment;
