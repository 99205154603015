import { useMemo } from "react";
import Spec from "../../../../../models/Spec";
import { usePerformRetrieveList } from "../../../../hooks/usePerformRetrieveList";

const dataComponent = {
  dataComponentId: "deletedSpecsDataComponentId",
  model: Spec,
  includes: [],
  apiRoute: "specs",
};

const getRevisionActivitySpecIds = (revisionActivities = [], operation) =>
  revisionActivities
    .filter(
      revisionActivity =>
        revisionActivity.operation == operation &&
        /^specs\.[0-9]+$/.test(revisionActivity.reference)
    )
    .map(({ reference }) => reference.split(".").at(1));

const getPreviousProperty = (spec, revisionActivities, property) => {
  const updateActivities = revisionActivities.filter(
    ({ operation }) => operation == "update"
  );

  const previous = updateActivities.find(
    ({ reference, columnName }) =>
      reference == `specs.${spec.id}` && columnName == property
  )?.previous;

  if (previous != undefined) {
    return previous || "--";
  }

  return spec[property] || "--";
};

export const useRetrieveSpecs = ({ purchaseOrder, revisionActivities }) => {
  const createdSpecIds = useMemo(
    () => getRevisionActivitySpecIds(revisionActivities, "create"),
    [revisionActivities]
  );
  const deletedSpecIds = useMemo(
    () => getRevisionActivitySpecIds(revisionActivities, "delete"),
    [revisionActivities]
  );

  const filters = useMemo(
    () => ({
      pageSize: -1,
      rootFilters: {
        $where: {
          $or: [
            {
              id: {
                $in: deletedSpecIds,
              },
            },
            {
              purchaseOrderId: purchaseOrder.id,
            },
          ],
        },
      },
      sort: [{ columnName: "purchaseOrderId", direction: "asc" }],
      params: { omitDefaultModifier: true, modifiers: ["withQuantityPrice"] },
    }),
    [deletedSpecIds, purchaseOrder.id]
  );
  const { isLoading, data: specs } = usePerformRetrieveList(
    dataComponent,
    filters
  );

  const permanentDeletedSpecs = specs.filter(
    ({ purchaseOrderId }) =>
      purchaseOrderId && purchaseOrderId != purchaseOrder.id
  );

  const deletedSpecIdsSet = new Set(
    [...permanentDeletedSpecs].map(({ id }) => id)
  );
  const createdSpecIdsSet = new Set(createdSpecIds);

  const filteredSpecs = specs
    .filter(
      ({ id }) => !deletedSpecIdsSet.has(id) && !createdSpecIdsSet.has(id)
    )
    .map(spec => {
      return {
        ...spec,
        totalQuantity: getPreviousProperty(
          spec,
          revisionActivities,
          "totalQuantity"
        ),
        priceCents: getPreviousProperty(spec, revisionActivities, "priceCents"),
      };
    });

  return {
    isLoading,
    specs: filteredSpecs,
    permanentDeletedSpecs,
  };
};
