import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import { push } from "connected-react-router";
import {
  getDataComponent,
  getDataComponentFlattenedRequestState,
} from "../../../../reducers/dataComponentReducer";
import { getResourcesV2 } from "../../../../utils/bluechipUtils";
import PurchaseOrder from "../../../../models/PurchaseOrder";
import { getRootFilters } from "./gridProps";
import PurchaseOrderPage from "./PurchaseOrderPage";
import { generateAndDownloadReport } from "../../../../actions/reportsActions";
import {
  initRFQListFilter,
  rfqFilterDataComponent,
} from "../../../../actions/projectsActions";
import {
  showSnackNotificationAction,
  setAutoSaveComponentId,
} from "../../../../actions/layoutActions";
import {
  performUpdateRequest,
  setReload,
} from "../../../../actions/dataComponentActions";
import {
  onUpdatePurchaseOrderStatus,
  processRequestsForPurchaseOrder,
} from "./PurchaseOrderFunctions";
import * as REQUEST_TYPES from "../../../../constants/RequestTypes";

export const dataComponentId = "PurchaseOrdersGrid";

export class PurchaseOrderContainer extends Component {
  componentDidMount() {
    const {
      match: { params },
      setAutoSaveComponentId,
      initRFQListFilter,
    } = this.props;
    setAutoSaveComponentId(dataComponentId);
    initRFQListFilter(params.projectId);
  }
  componentDidUpdate({ dataComponent: prevDataComponent }) {
    const {
      dataComponent,
      setReload,
      showSnackNotificationAction,
    } = this.props;

    processRequestsForPurchaseOrder(
      prevDataComponent,
      dataComponent,
      dataComponentId,
      {
        setReload,
        showSnackNotificationAction,
        snackMessage: "Purchase Order issue successfully canceled.",
      }
    );
  }
  handleDownloadPO = ({ id }, type) => {
    const { generateAndDownloadReport } = this.props;
    generateAndDownloadReport({
      format: type,
      purchaseOrderId: id,
      templateName: "purchase-order-detail",
    });
  };
  handleUpdatePurchaseOrderStatus = (
    purchaseOrder,
    status,
    dataComponentId,
    performUpdateRequest
  ) => {
    onUpdatePurchaseOrderStatus(
      purchaseOrder,
      status,
      dataComponentId,
      performUpdateRequest
    );
  };
  render() {
    const {
      match: { params },
      purchaseOrderDataComponent,
      performUpdateRequest,
      push,
      rfqs,
    } = this.props;

    return (
      <PurchaseOrderPage
        dataComponent={purchaseOrderDataComponent}
        apiFilters={{
          rootFilters: getRootFilters(params.projectId),
          params: {
            omitDefaultModifier: true,
            modifiers: ["withShipmentsDates"],
          },
        }}
        defaultSorting={[{ columnName: "number", direction: "asc" }]}
        onDownloadPO={this.handleDownloadPO}
        onCancelPurchaseOrder={purchaseOrder =>
          this.handleUpdatePurchaseOrderStatus(
            purchaseOrder,
            "Pending Approval",
            dataComponentId,
            performUpdateRequest,
            setReload
          )
        }
        model={PurchaseOrder}
        includes={[
          "vendor",
          "bidGroup",
          "shipAddress.location",
          "shipVendorAddress.[vendor.location, location]",
        ]}
        apiRoute="purchase-orders"
        projectId={params.projectId}
        clientId={params.clientId}
        push={push}
        rfqs={rfqs}
      />
    );
  }
}

PurchaseOrderContainer.propTypes = {
  dataComponent: PropTypes.object.isRequired,
  purchaseOrderDataComponent: PropTypes.object.isRequired,
  generateAndDownloadReport: PropTypes.func.isRequired,
  setAutoSaveComponentId: PropTypes.func.isRequired,
  setReload: PropTypes.func.isRequired,
  showSnackNotificationAction: PropTypes.func.isRequired,
  performUpdateRequest: PropTypes.func.isRequired,
  initRFQListFilter: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  push: PropTypes.func,
  rfqs: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.string })),
};

export const mapStateToProps = state => {
  const rfqsDataComponent = getDataComponent(rfqFilterDataComponent, state);

  return {
    dataComponent: getDataComponent(dataComponentId, state),
    purchaseOrderDataComponent: getDataComponentFlattenedRequestState(
      dataComponentId,
      state
    ),
    rfqs: getResourcesV2(rfqsDataComponent, state, REQUEST_TYPES.LIST, []),
  };
};

const mapDispatchToProps = {
  push,
  generateAndDownloadReport,
  setAutoSaveComponentId,
  performUpdateRequest,
  setReload,
  showSnackNotificationAction,
  initRFQListFilter,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PurchaseOrderContainer)
);
