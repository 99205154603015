import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import propTypes from "../../constants/propTypes";

import { BWIntent } from "./BWIntent";
import { TitleTag } from "../layout/DetailAndCycle";
import TagText from "./Tags/TagText";
import Loader from "./Loader";
import { usePODelivered } from "../hooks/usePODelivered";

const Wrapper = styled.div`
  ${({ marginRight }) => (marginRight ? "margin-right: 16px;" : "")}
`;

const statusIntent = {
  Issued: BWIntent.SUCCESS,
  "Needs Follow Up": BWIntent.DANGER,
  Approved: BWIntent.PRIMARY,
  "Pending Approval": BWIntent.PRIMARY,
};

const getText = ({ status, number, isRevised }, displayNumber) => {
  let statusText = isRevised ? `${status}, Revised` : status;
  if (!displayNumber) return statusText;
  return `PO#${number} ${statusText}`;
};

const getNeedsFollowUpText = (
  { needsFollowUp, status },
  displayNeedsFollowUp
) => {
  if (displayNeedsFollowUp && needsFollowUp && status === "Issued") {
    return "Needs Follow Up";
  }
};

const POStatusTag = ({
  purchaseOrder,
  displayNumber,
  displayNeedsFollowUp = true,
  displayDelivered = true,
  spaceBetween = true,
  marginRight,
}) => {
  const Tag = spaceBetween ? TitleTag : TagText;

  const needsFollowUp = getNeedsFollowUpText(
    purchaseOrder,
    displayNeedsFollowUp
  );
  const { status, revisionStatus } = purchaseOrder;

  const { isDelivered, isLoading } = usePODelivered(purchaseOrder);

  return (
    <Wrapper marginRight={marginRight}>
      <Tag intent={statusIntent[status]}>
        {getText(purchaseOrder, displayNumber)}
      </Tag>
      {revisionStatus && (
        <Tag intent="warning" textIntent="dark">
          CO {revisionStatus}
        </Tag>
      )}
      {needsFollowUp && (
        <Tag intent={statusIntent[needsFollowUp]}>{needsFollowUp}</Tag>
      )}
      {displayDelivered && isDelivered && (
        <Loader loading={isLoading}>
          <Tag intent={BWIntent.SUCCESS}>Delivered</Tag>
        </Loader>
      )}
    </Wrapper>
  );
};
POStatusTag.propTypes = {
  purchaseOrder: propTypes.purchaseOrder,
  replacers: PropTypes.objectOf(PropTypes.string),
  displayNumber: PropTypes.bool,
  displayNeedsFollowUp: PropTypes.bool,
  displayDelivered: PropTypes.bool,
  spaceBetween: PropTypes.bool,
  marginRight: PropTypes.bool,
};

export default POStatusTag;
