import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { createSelector } from "reselect";
import _isEqual from "lodash/isEqual";

import GridAPI from "./GridAPI";
import { memo } from "react";
import { createDeepEqualSelector } from "../../../../utils/selectors";
import { getBluechipResources } from "../../../../utils/bluechipUtils";
import { getDataComponentRequestState } from "../../../../reducers/dataComponentReducer";
import {
  fetchData,
  initDataComponent,
  performRetrieveListRequest,
  reorder,
  setPage,
  setPageNumberAction,
  setPageSize,
  setReload,
  setSelection,
  setSort,
} from "../../../../actions/dataComponentActions";
import withEditableCellController from "../../BWGrid/withEditableCellController";

export const getDefaultRows = loading => {
  return loading
    ? [...new Array(10)].map((_, index) => ({ id: index.toString() }))
    : [];
};

export const mapStateToProps = () => {
  const rowsSelector = createSelector(
    (state, { dataComponent }) => getBluechipResources(dataComponent, state),
    (state, { dataComponent }) => dataComponent.loading,
    (rows, loading) => (rows ? rows : getDefaultRows(loading))
  );

  const defaultSortingSelector = createDeepEqualSelector(
    (_, { defaultSorting }) => defaultSorting,
    defaultSorting => defaultSorting || [{ columnName: "id", direction: "asc" }]
  );

  const apiFiltersSelector = createDeepEqualSelector(
    (_, { rootFilters }) => rootFilters,
    (_, { apiFilters }) => apiFilters,
    defaultSortingSelector,
    (rootFilters, apiFilters = { rootFilters }) => ({
      ...apiFilters,
    })
  );

  return (state, ownProps) => {
    const { dataComponent } = ownProps;
    return {
      apiFilters: apiFiltersSelector(state, ownProps),
      defaultSorting: defaultSortingSelector(state, ownProps),
      rows: rowsSelector(state, { dataComponent }),
      requestState: getDataComponentRequestState(
        dataComponent.dataComponentId,
        state
      ),
      pageNumber: dataComponent.pageNumber,
      isLoading: dataComponent.loading,
      reload: dataComponent.reload,
    };
  };
};

export const mapDispatchToProps = (dispatch, initialProps) => {
  const { apiRoute, model, includes } = initialProps;
  const { dataComponentId } = initialProps.dataComponent;

  const actionWrappers = bindActionCreators(
    {
      fetchData: () => fetchData(dataComponentId),
      setPage: pageNumber => setPage(dataComponentId, pageNumber),
      setPageNumber: pageNumber =>
        setPageNumberAction(dataComponentId, pageNumber),
      setPageSize: pageSize => setPageSize(dataComponentId, pageSize, false),
      setSort: sort => setSort(dataComponentId, sort),
      setSelection: selection => setSelection(dataComponentId, selection),
      performRetrieveListRequest: filters =>
        performRetrieveListRequest(dataComponentId, filters),
      setReload: reload => setReload(dataComponentId, reload),
      initDataComponent: () =>
        initDataComponent(
          dataComponentId,
          model,
          includes,
          apiRoute,
          false,
          "v2"
        ),
    },
    dispatch
  );

  const onReorderSelector = createSelector(
    (_, { onReorder }) => onReorder,
    onReorder => {
      return (reorderInfo, item) => {
        dispatch(reorder(dataComponentId, reorderInfo));
        onReorder(reorderInfo, item);
      };
    }
  );

  return (dispatch, props) => {
    return {
      actions: {
        ...actionWrappers,
        onReorder: onReorderSelector(dispatch, props),
      },
    };
  };
};

export const deepEqualProps = [
  "apiFilters",
  "dataComponent",
  "tableComponents",
  "includes",
  "rootFilters",
  "defaultSorting",
  "columns",
  "columnBands",
  "columnExtensions",
  "fixedColumns",
  "pagingPanelProps",
];

export function areEqual(prevProps, nextProps) {
  return Object.keys(nextProps).every(propName => {
    const nextVal = nextProps[propName];
    const prevVal = prevProps[propName];
    return deepEqualProps.includes(propName)
      ? _isEqual(nextVal, prevVal)
      : nextVal === prevVal;
  });
}

export default memo(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withEditableCellController(GridAPI)),
  areEqual
);
