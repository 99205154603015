import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router";
import isEmpty from "lodash/isEmpty";

import { closeModalDialog } from "../../../../actions/layoutActions";
import {
  initDataComponent,
  performUpdateRequest,
  setReload,
} from "../../../../actions/dataComponentActions";
import { ActionButtons, FormGrid, FormikForm } from "../../../forms";
import { inputs } from "../../../inputs/inputConfigs";
import useActions from "../../../hooks/useActions";
import Spec from "../../../../models/Spec";
import { FormTitle } from "../../ProjectDetail/Shipments/EditMultipleShipments/EditMultipleShipmentsModal";
import { useBluechipResources } from "../../../hooks/useResources";
import { useUpdateProcessRequests } from "../../../hooks/useProcessRequest";
import { useDatacomponent } from "../../../hooks/useDatacomponent";
import { dataComponentId } from "../specContainerFunctions";

const getFields = clientId => ({
  groups: [
    {
      items: [
        {
          input: {
            ...inputs.glCodes,
            label: "GL Codes",
            dataComponentId: "select-gl-codes",
            APIOptions: {
              ...inputs.glCodes.APIOptions,
              rootFilters: {
                $where: {
                  clientId,
                },
              },
            },
          },
          grid: { xs: 12 },
        },
      ],
    },
  ],
});

export const specDataComponentId = "Spec-Forecast-Comment";

const SetGLCodes = ({ selectedIds }) => {
  const actions = useActions({
    initDataComponent,
    closeModalDialog,
    performUpdateRequest,
    setReload,
  });

  const specs = useBluechipResources(Spec, ["glCodes"], selectedIds);

  useEffect(() => {
    actions.initDataComponent(specDataComponentId, Spec, [], "specs", true);
  }, [actions]);

  const submit = ({ glCodes }) => {
    actions.performUpdateRequest(
      specDataComponentId,
      specs.map(({ id, glCodes: originalGLCodes = [] }) => ({
        id,
        glCodes: [
          ...new Set([...originalGLCodes, ...glCodes].map(({ id }) => id)),
        ].map(id => ({ id })),
      }))
    );
  };

  const dataComponent = useDatacomponent(specDataComponentId);
  useUpdateProcessRequests(dataComponent, {
    onSuccess: () => {
      actions.setReload(dataComponentId, true);
      actions.closeModalDialog();
    },
  });

  const { clientId } = useParams();

  return (
    <React.Fragment>
      <FormikForm initialValues={{ glCodes: [] }} onSubmit={submit}>
        {({ handleSubmit, values, errors, ...formikProps }) => {
          return (
            <React.Fragment>
              <FormGrid
                fields={getFields(clientId)}
                values={values}
                errors={errors}
                {...formikProps}
              />
              <FormTitle>GL Codes will be added to selected Specs</FormTitle>
              <ActionButtons
                onSend={handleSubmit}
                isModal={true}
                listeners={[specDataComponentId]}
                disabled={isEmpty(values.glCodes)}
              />
            </React.Fragment>
          );
        }}
      </FormikForm>
    </React.Fragment>
  );
};

SetGLCodes.propTypes = {
  selectedIds: PropTypes.array.isRequired,
};

export default SetGLCodes;
