import React, { useCallback } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import styled from "styled-components";
import _padStart from "lodash/padStart";
import _get from "lodash/get";
import Remove from "@material-ui/icons/Remove";

import { openModalDialog } from "../../../../actions/layoutActions";
import { BWGridLocal, GridTable } from "../../../ui/BWGrid";
import PageSection from "../../../layout/PageSection";
import Date from "../../../ui/BWGrid/columns/Date";
import propTypes from "../../../../constants/propTypes";
import RevisionDownloadIcon from "./RevisionDownloadIcon";
import Price from "../../../ui/Price";
import { PRIMARY_DATE_FORMAT } from "../../../../constants/formats";

const Version = styled.span`
  font-weight: bold;
`;

export const DashIcon = styled(Remove)`
  font-family: Open Sans, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  width: 14px;
  height: 14px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.87);
`;

const VersionLink = styled.a`
  cursor: pointer;
  color: #57abff;
  text-decoration: none;
`;

const renderRow = function ValueOrDash(revision, key, type, onClick) {
  const value = revision[key];
  const element = value ? value : <DashIcon />;
  if (onClick && value) {
    return <VersionLink onClick={onClick}>{element}</VersionLink>;
  }
  return element;
};

export const GridWrapper = styled.div`
  margin-top: ${({ noMarginTop }) => (noMarginTop ? "0" : "24px")};
  max-height: 300px;
  overflow: auto;
`;

const columns = [
  "version",
  "issueDate",
  "changesMade",
  "type",
  "notes",
  "impactToPo",
  "poTotal",
  "download",
];

const columnOptions = (handleDownloadPDF, handleOpenChanges) => ({
  version: {
    title: "CO #",
    sortingEnabled: false,
    render: ({ version }) =>
      version >= 0 && (
        <Version>{version === 0 ? "---" : _padStart(version, 3, "0")}</Version>
      ),
  },
  issueDate: {
    title: "Date",
    sortingEnabled: false,
    render: ({ issueDate }) =>
      issueDate && <Date date={issueDate} format={PRIMARY_DATE_FORMAT} />,
  },
  changesMade: {
    sortingEnabled: false,
    render: (revision, key) =>
      revision &&
      renderRow(
        {
          [key]:
            _get(revision, "changesMade", 0) > 0 &&
            `${_get(revision, "changesMade")} Changes`,
        },
        key,
        null,
        handleOpenChanges(revision)
      ),
  },
  type: {
    sortingEnabled: false,
    title: "Revision Type",
    render: renderRow,
    fill: true,
  },
  notes: {
    sortingEnabled: false,
    title: "Revision Notes",
    render: renderRow,
    fill: true,
  },
  impactToPo: {
    title: "Impact to PO",
    sortingEnabled: false,
    render: ({ impactToPo, currency }, key) =>
      renderRow(
        {
          impactToPo: impactToPo && (
            <Price number={impactToPo} currency={currency} />
          ),
        },
        key
      ),
  },
  poTotal: {
    title: "PO Total",
    sortingEnabled: false,
    render: ({ poTotal, currency }, key) =>
      renderRow(
        {
          poTotal: poTotal && <Price number={poTotal} currency={currency} />,
        },
        key
      ),
  },
  download: {
    sortingEnabled: false,
    render: revision =>
      !revision.legacyId && (
        <RevisionDownloadIcon revision={revision} onClick={handleDownloadPDF} />
      ),
  },
});
export const IssuedHistory = ({
  revisions,
  purchaseOrder,
  handleDownloadPDF,
  openModalDialog,
  loading,
  noMarginTop,
  pageSectionProps = {},
}) => {
  const handleRowClick = useCallback(
    row => () => {
      openModalDialog(
        `Change Order ${_padStart(row.version, 3, "0")}`,
        "RevisionActivities",
        {
          revisionId: row.id,
          changesMade: row.changesMade,
          purchaseOrder,
        },
        true,
        true,
        {
          subtitle: `${row.type ? `${row.type} -` : ""}  ${row.changesMade ||
            0} Changes`,
        }
      );
    },
    [openModalDialog, purchaseOrder]
  );

  return (
    <PageSection headerText="Issued History" {...pageSectionProps}>
      <GridWrapper noMarginTop={noMarginTop}>
        <BWGridLocal
          id="issue-history-grid"
          rows={revisions}
          gridConfig={{ pageSize: 10, totalRows: revisions.length }}
          noBottomMargin={true}
          isLoading={loading}
        >
          <GridTable
            columns={columns}
            columnOptions={columnOptions(handleDownloadPDF, handleRowClick)}
          />
        </BWGridLocal>
      </GridWrapper>
    </PageSection>
  );
};

IssuedHistory.propTypes = {
  revisions: PropTypes.arrayOf(propTypes.revision),
  handleDownloadPDF: PropTypes.func,
  openModalDialog: PropTypes.func,
  purchaseOrder: propTypes.purchaseOrder,
  loading: PropTypes.bool,
  noMarginTop: PropTypes.bool,
};

IssuedHistory.defaultProps = {
  revisions: [],
};

export const mapDispatchToProps = {
  openModalDialog,
};

export default connect(null, mapDispatchToProps)(IssuedHistory);
