import React from "react";
import { useState, useEffect, useCallback, useRef } from "react";
import PropTypes from "prop-types";
import _set from "lodash/set";
import _pick from "lodash/pick";
import _debounce from "lodash/debounce";

import { Wrapper } from "./components";
import Input from "./Input";
import Loader from "../../../../Loader";
import { useIsProjectClosed } from "../../../../../hooks/useIsProjectClosed";
import { blueChipActions } from "../../../../../../store/blueChipConfig";
import { removeControlCharacters } from "../../../../../inputs/utils";

const shouldUpdate = (initialValues, values, rowsSettings) =>
  rowsSettings.reduce(
    (prev, { path }) => prev || initialValues[path] != values[path],
    false
  );

export const updateValues = (
  initialValuesRef,
  initialValues,
  setValues,
  rowsSettings
) => () => {
  if (shouldUpdate(initialValues, initialValuesRef.current, rowsSettings)) {
    setValues(initialValues);
  }
  initialValuesRef.current = initialValues;
};

export const updateBlueChipResources = ({ rowsSettings, body, values }) => {
  rowsSettings.forEach(({ path }) => {
    if (body[path]) {
      blueChipActions.updateResource({
        id: values.id,
        type: "specs",
        attributes: {
          [path]: body[path],
        },
      });
    }
  });
};

const DetailComponent = ({
  row: initialValues,
  dataComponentId: srcDataComponentId,
  actions,
  itemId,
  rowsSettings,
  isValid,
  debounceDelay = 1000,
  // eslint-disable-next-line sonarjs/cognitive-complexity
}) => {
  const [values, setValues] = useState(initialValues);
  const pendingFields = useRef(new Set());
  const rowId = useRef(initialValues.id);
  const initialValuesRef = useRef(initialValues);

  const isProjectClosed = useIsProjectClosed();

  if (rowId.current !== initialValues.id) {
    setValues(initialValues);
    rowId.current = initialValues.id;
  }

  useEffect(
    updateValues(initialValuesRef, initialValues, setValues, rowsSettings),
    [initialValues]
  );

  useEffect(() => {
    if (itemId) {
      actions.initRow(srcDataComponentId, itemId);
    }
  }, [srcDataComponentId, itemId, actions]);

  const saveChanges = useCallback(
    _debounce(values => {
      const body = _pick(values, [...pendingFields.current]);
      pendingFields.current = new Set();

      if (isValid && !isValid(values)) {
        setValues(initialValues);
        return;
      }

      actions.updateRow(srcDataComponentId, itemId, body);
      updateBlueChipResources({ rowsSettings, body, values });
    }, debounceDelay),
    [srcDataComponentId, itemId, actions]
  );

  const handleLocalChange = (path, value) => {
    const newValues = { ...values };
    _set(newValues, path, removeControlCharacters(value, true));
    setValues(newValues);

    pendingFields.current.add(path);
    saveChanges(newValues);
  };

  return (
    <Wrapper>
      {rowsSettings.map(({ path, ...additionalProps }) => (
        <Loader key={path} height="24px">
          <Input
            path={path}
            {...additionalProps}
            values={values}
            itemId={itemId}
            onChangeValue={handleLocalChange}
            onBlur={saveChanges.flush}
            disabled={additionalProps.disabled || isProjectClosed}
          />
        </Loader>
      ))}
    </Wrapper>
  );
};

DetailComponent.propTypes = {
  row: PropTypes.shape({}),
  rowsSettings: PropTypes.arrayOf(
    PropTypes.shape({
      path: PropTypes.string,
      label: PropTypes.string,
      disabled: PropTypes.bool,
      placeholder: PropTypes.string,
      alwaysVisible: PropTypes.bool,
      InputComponent: PropTypes.func,
      isEmptyFn: PropTypes.func,
    })
  ),
  actions: PropTypes.shape({
    updateRow: PropTypes.func.isRequired,
    initRow: PropTypes.func.isRequired,
  }),
  validationSchema: PropTypes.shape({ valid: PropTypes.func }),
  dataComponentId: PropTypes.string,
  itemId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  isValid: PropTypes.func,
  debounceDelay: PropTypes.number,
};

DetailComponent.defaultProps = {};

export default DetailComponent;
